.item {
  background-color: #ebeff2;
  height: 100%;
  min-height: 170px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;

  :global {
    .file-preview {
      .button:focus {
        box-shadow: none;
      }
    }

    .file-items {
      display: flex;
    }

    .file-item {
      border: 1px solid #eceff1;
      border-radius: 4px;
      position: relative;
      margin: 5px;
      padding: 0;
      height: 110px;
      width: 110px;
      align-items: flex-start;

      &__new {
        border: 1px solid #2bc97c;
      }

      .file-name-tooltip {
        background: #f7f9f9;
        color: #353a45;
        display: block;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        line-height: 1.3;
        position: absolute;
        padding: 2px 5px;
        margin-right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        visibility: visible;
        cursor: pointer;
        overflow: visible;
      }

      .file-name {
        width: 100%;
        color: #2f333e;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .file-size {
        display: block;
        color: #84939a;
      }

      .file-buttons {
        display: none;
        background: inherit;
        position: absolute;
        left: 0;
        top: 0;
        width: inherit;
        z-index: inherit;
        margin-left: auto;

        .action-icon-view {
          display: none;
        }

        .button {
          background: transparent;
          color: #353a45;

          &:hover {
            color: #039be5;
            background: white;
          }
        }
      }

      &:hover {
        cursor: pointer;

        .file-buttons {
          display: none;

          &__download {
            display: none;
          }

          &__view {
            display: none;
          }

          &__trash {
            display: none;
          }

          .new-file {
            display: none;
          }

          .file-badge {
            display: none;
          }
        }
      }
    }

    .button-file-input {
      position: relative;
      display: block;
      margin: 5px;

      input {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        text-align: right;
        filter: alpha(opacity=0);
        opacity: 0;
        outline: none;
        background: white;
        cursor: inherit;
        display: block;
      }
    }
  }
}
