.item {
  position: relative;

  :global {
    .file-item {
      margin: 6px;
      max-width: 110px;
      min-width: 110px;
      max-height: 130px;
      background: #ebeff2;
      border-radius: 3px;
      border: 2px solid #ebeff2;

      &.active-state {
        border: 2px solid #039be6;
      }

      .file-name-tooltip {
        background: #f7f9f9;
        color: #353a45;
        display: block;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        line-height: 1.3;
        position: relative;
        padding: 2px 5px;
        height: 40px;
        cursor: pointer;

        .file-dispatcher {
          display: none;
        }
      }

      .file-size {
        color: #84939a;
      }

      .file-name {
        width: 100%;
        color: #2f333e;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .is-sending {
        background: transparent;
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 5;
        width: 14px;
        height: 14px;
        line-height: 1;

        .fa {
          color: #2bc97c;
        }

        .status {
          display: none;
        }
      }

      .file-preview {
        position: relative;
        height: 80px;
        cursor: pointer;

        &__hover {
          background: rgba(#353a45, 0.6);
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 100;
          opacity: 0;
          transition: all 0.3s;
        }

        img {
          width: 110px;
          height: 80px;
          object-fit: cover;
        }

        .button-block {
          visibility: hidden;
          position: absolute;
          top: -3px;
          left: inherit;
          right: -10px;
          z-index: 150;

          .action-buttons {
            background: transparent;

            .fa {
              color: #fff;
            }
          }

          .button-block__list {
            left: inherit;
            right: -60px;
            top: 30px;
            min-width: 80px;
            max-width: 80px;
            background-color: #f7f9f8;

            .button {
              max-width: 80px;
              min-width: 80px;
              border-radius: 2px;
              font-size: 14px;
              font-weight: 400;
              height: 26px;
              line-height: 26px;
              padding: 0 7px;
              text-transform: lowercase;
            }
          }

          &:hover {
            .button-block {
              visibility: visible;
            }

            .file-preview__hover {
              opacity: 1;
            }
          }

          &.active {
            visibility: visible;

            & + .file-preview__hover {
              opacity: 1;
            }
          }
        }

        .file-thumbnail {
          display: flex;
          position: absolute;
          height: 100%;
          width: 100%;
          overflow: hidden;
          text-align: center;
          font-size: 24px;
          border-radius: 4px;
          justify-content: center;
          align-items: center;
          color: #84939a;
        }
      }

      .checkbox-custom__block {
        position: absolute;
        z-index: 300;
        top: 10px;
        left: 5px;
      }

      .file-item-block {
        display: block;
        overflow: hidden;
      }

      &.list {
        max-width: 100%;
        max-height: 100%;
        background: none;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        padding: 6px 12px;
        border: 0;
        border-bottom: 1px solid #ebeff2;

        .file-name {
          font-size: 16px;
          line-height: 1.2;
          max-width: 420px;
        }

        .file-item-block {
          display: flex;
          align-items: center;
        }

        .file-name-tooltip {
          background: transparent;
          color: #353a45;
          display: block;
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          line-height: 1.3;
          position: relative;
          padding: 2px 5px;
          height: 40px;
          cursor: pointer;

          .file-dispatcher {
            display: block;
            color: #84939a;
          }

          .file-size {
            display: none;
          }
        }

        .file-preview {
          height: auto;
          margin-left: 30px;
          margin-right: 10px;

          > *:not(.file-thumbnail) {
            display: none;
          }

          .file-thumbnail {
            display: block;
            position: relative;
            overflow: hidden;
            height: 34px;
            width: 34px;
            text-align: center;
            font-size: 14px;
            line-height: 33px;
            color: #84939a;
            border-radius: 4px;
            background-color: #ebeff2;
          }
        }

        .checkbox-custom__block {
          top: 12px;
          left: 6px;
          margin-left: 6px;
        }

        .is-sending {
          background: #fff;
          border-radius: 0;
          position: relative;
          width: auto;
          height: auto;
          float: right;

          .status {
            display: block;
            color: #353a45;
            font-size: 14px;
            line-height: 24px;

            em {
              font-size: 16px;
              color: #84939a;
            }
          }

          .fa {
            display: none;
          }
        }
      }
    }
  }
}
