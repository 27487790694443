.clipboard_wrap {
  &_absolute {
    position: absolute;
    top: 0;
    right: 0;
    margin: 6px;
  }

  &_relative {
    position: relative;
  }
}

.copied {
  position: absolute;
  top: -100%;
  right: -50%;
  background-color: #fff6ed;
  border: 1px solid rgba(#ffab40, 0.2);
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  color: #06a350;
  font-size: 12px;
  padding: 0 2px;
  white-space: nowrap;
}
