.modal {
  margin-top: 15px;

  :global {
    .item {
      border: 1px solid #ebeff2;
      background: #f6f9f8;
      border-radius: 5px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 10px 15px;
      cursor: pointer;
      margin-bottom: 12px;
      color: #363a45;

      &.checked {
        background-color: #ebeff2;
      }

      &.closed {
        color: #84939a;
      }
    }
  }
}
