.formContainer {
  padding: 0 20px 50px 20px;
}

.blockHeading {
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 300;
  line-height: 35px;
}

.row {
  margin-top: 40px;
}

.halfWidth {
  width: calc(50% - 10px);
}
