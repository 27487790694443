@import 'assets/scss/color-palette';

.spinnerWrapper {
  height: 44px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 !important;
  background-color: $lily-white;
  z-index: 1;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: appearDown 0.2s;

  .icon {
    color: $custom-blue;
    margin-right: 10px;
  }

  @keyframes appearDown {
    0% {
      translate: 0 -20px;
      opacity: 0;
    }

    100% {
      opacity: 1;
      translate: 0 0;
    }
  }
}
