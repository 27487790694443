.item {
  border: 1px solid #ebeff2;
  border-radius: 5px;
  margin-top: 10px;
  line-height: 1.3;

  :global {
    .header {
      background: #ebeff2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 5px 5px 10px;
      height: 45px;

      .badge {
        background: rgba(63, 196, 255, 0.2);
        font-weight: 400;
        font-size: 14px;
        margin-left: 5px;
      }
    }

    .main {
      display: none;

      &.open {
        display: block;
      }
    }

    .footer {
      background-color: #f7f9f8;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .total {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 700;
        min-width: 230px;

        .data {
          margin-left: 20px;
          min-width: 50%;
          width: auto;
          padding-right: 20px;
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .settlements-group-table {
      width: 100%;

      thead {
        tr {
          th {
            background: #f7f9f8;
            padding: 10px;
            color: #84939a;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      tbody {
        tr {
          td {
            vertical-align: top;
            border: 1px solid #ebeff2;
            padding: 10px;

            &:nth-child(1) {
              width: 150px;
              border-left: none;
            }

            &:nth-child(2) {
              width: 410px;
            }

            &:nth-child(3) {
              width: 100px;

              > div {
                width: 100px;
              }
            }

            &:nth-child(4) {
              width: 175px;
            }

            &:nth-child(5) {
              white-space: nowrap;
              padding-right: 30px;
            }

            .payment {
              display: flex;
              justify-content: flex-start;
            }
          }
        }
      }

      &__total {
        background: #f7f9f8;
        display: flex;
        justify-content: flex-end;
        padding: 10px;
        border-bottom: 1px solid #ebeff2;

        .total-miles {
          width: 340px;
          display: flex;
          justify-content: flex-end;

          .data {
            margin-left: 20px;
            padding-left: 10px;
            width: 122px;
          }
        }

        .total-payment {
          width: 280px;
          display: flex;
          justify-content: flex-end;

          .data {
            margin-left: 20px;
            padding-right: 20px;

            div {
              display: flex;
              justify-content: space-between;

              .reim {
                position: relative;

                &::before {
                  position: absolute;
                  left: -10px;
                  content: '+';
                }
              }

              .ded {
                position: relative;

                &::before {
                  position: absolute;
                  left: -10px;
                  content: '-';
                }
              }
            }
          }
        }
      }
    }
  }
}
