@import "../color-palette";

.custom-redesign-form {
  .form-group {
    margin-bottom: 10px;
  }

  .form-group.has-error {
    position: relative;
  }

  .invalid-feedback.error {
    display: none;
  }

  .tooltip-custom-bottom {
    z-index: 301;
    display: inline-block;
    position: relative;
    text-decoration: none;
    border-radius: 3px;
    color: #ff5253;
    background: #fff;
    padding: 0;
    border: none;
    top: 100%;
    margin-top: 2px;
    right: 0;
    line-height: 1.3;
    font-weight: 400;
    font-size: 12px;
  }

  .form-content,
  .form-content-modal {
    margin: 15px 0 0;
    padding: 0;

    h2 {
      font-weight: 300 !important;
      margin: 30px 0 !important;
    }
  }

  .form-content-modal {
    margin: 0;
  }

  .form-action-btns {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
  }

  .form-tab__main-info {
    padding: 0 20px 0 20px;
  }

  .optional {
    color: $regent-gray;
    font-weight: 400;
  }

  .form-label {
    font-size: 14px;
    font-weight: 400;
    text-transform: none;

    .optional {
      text-transform: none;
    }

    .required {
      color: #ff5459;
    }
  }

  .relative {
    position: relative;
  }

  .header-actions-right {
    position: absolute;
    right: 0;
    top: 0;
  }

  .form-control {
    border: 1px solid #eceff1;
    box-shadow: none;
    min-width: 100px;
    height: 24px;
  }

  .Select-control {
    background-color: $snow-drift !important;
  }

  input,
  textarea {
    background-color: $snow-drift !important;
  }

  input.form-control {
    min-height: 24px;
  }

  textarea.form-control {
    height: auto;
  }

  input.form-control,
  textarea.form-control {
    background-color: #f5f6f6 !important;
    border-color: #7a8185 !important;
    box-shadow: none !important;
    box-sizing: border-box;
    color: #353a45 !important;
    font-size: 14px;
    padding: 5px;
    resize: vertical;
    transition: none !important;

    &::placeholder {
      color: $regent-gray !important;
      font-size: 12px;
    }

    &::-moz-placeholder {
      color: $regent-gray !important;
      font-size: 12px;
    }

    &::-webkit-input-placeholder {
      color: $regent-gray !important;
    }

    &:-moz-placeholder {
      color: $regent-gray !important;
      font-size: 12px;
    }

    &:hover {
      border-color: $bright-gray !important;
    }

    &:focus {
      border-color: $cerulean !important;
      box-shadow: 0 0 3px 2px rgba(3, 155, 230, 0.15) !important;
    }

    &:disabled {
      background-color: $porcelain !important;
      border: 1px solid transparent !important;
      box-shadow: none !important;
      color: $bright-gray !important;

      &::placeholder {
        color: $regent-gray !important;
      }

      &::-moz-placeholder {
        color: $regent-gray !important;
      }

      &::-webkit-input-placeholder {
        color: $regent-gray !important;
      }

      &:-moz-placeholder {
        color: $regent-gray !important;
      }
    }

    &[disabled] {
      background-color: $porcelain !important;
      border: 1px solid transparent !important;
      box-shadow: none !important;
      color: $bright-gray !important;
    }
  }

  .Select {
    .Select-control {
      border-color: $tower-gray !important;

      .Select-placeholder {
        padding-right: 30px;
      }

      &:hover {
        border-color: $bright-gray !important;
      }
    }

    &.is-focused {
      .Select-control {
        border-color: $cerulean !important;
        box-shadow: 0 0 3px 2px rgba(3, 155, 230, 0.15) !important;
      }
    }

    &.is-disabled {
      .Select-control {
        background-color: $porcelain !important;
        border: 1px solid transparent !important;
        box-shadow: none !important;
        color: $bright-gray !important;
      }
    }

    &.Select--single {
      .Select-value {
        padding-right: 30px;
      }
    }

    .Select-menu-outer {
      z-index: 2;

      .Select-menu {
        .Select-option {
          &.is-selected {
            background-color: transparent;
            font-weight: 600;
          }

          &.is-focused {
            background-color: $snow-drift;
          }
        }
      }
    }

    &.Select--multi {
      .Select-control {
        .Select-multi-value-wrapper {
          .Select-value {
            background-color: $white;
            border: 1px solid transparent;
            border-radius: 2px;
            color: $regent-gray;
            display: inline-block;
            font-size: 14px;
            line-height: 1.4;
            margin-left: 5px;
            margin-top: 5px;
            vertical-align: top;

            .Select-value-icon {
              border-right: none;
              color: transparent;
              display: inline-block;
              font-size: 16px;
              height: 20px;
              line-height: 20px;
              margin: 0;
              padding: 0;
              vertical-align: middle;
              width: 20px;

              &::before {
                color: $regent-gray;
                content: '\f00d';
                display: block;
                font-family: FontAwesome, sans-serif;
                height: 20px;
                line-height: 20px;
                text-align: center;
                width: 20px;
              }

              &:hover {
                background-color: transparent;

                &::before {
                  color: $cerulean;
                }
              }
            }

            .Select-value-label {
              color: $tundora;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }

  .has-error .form-control {
    border-color: $sunset-orange !important;
    padding: 5px;
  }

  .has-error .Select .Select-control,
  .has-error .Select .Select-control:hover {
    background-color: #ffeded !important;
    border-color: $sunset-orange !important;
  }

  .has-error .Select.is-focused {
    .Select-control {
      background-color: #ffeded !important;
      border-color: $sunset-orange !important;
      box-shadow: 0 0 3px 2px rgba(255, 82, 83, 0.2) !important;
    }
  }

  .has-error .Select .Select-input > input {
    background-color: transparent !important;
  }

  .has-error .form-control:focus,
  .has-error .form-control:hover:not([disabled]) {
    background-color: #f5f6f6 !important;
    border-color: $sunset-orange !important;
    box-shadow: none !important;
  }

  .has-error .control-label {
    color: $tuna;
  }

  .has-feedback label ~ .form-control-feedback {
    bottom: 1px;
    top: unset;
  }

  .withgroup .Select-menu-outer .Select-option {
    padding-left: 2em;
  }

  .withgroup .Select-option.is-disabled {
    color: dimgray !important;
    cursor: default;
    font-weight: bold !important;
    padding-left: 1em;
  }

  .input-group {
    z-index: 1;
  }

  .iw_cb_shell.iw_cb_shell_checked {
    .label-with-link {
      line-height: 36px;

      a {
        display: none;
      }
    }
  }

  .input-mask-flex {
    display: flex;
    align-items: center;

    >span {
      color: #363a45;
      margin: 0 5px 15px;
    }

    .form-control {
      padding-right: 15px;
      color: #84939a !important;
      letter-spacing: 3px;
    }

    &.ssn-num {
      >.form-group {
        width: 55px;

        .form-control {
          min-width: inherit;
          text-align: center;
        }

        &:nth-child(1) {
          width: 65px;
        }

        &:last-child {
          width: 80px;
        }
      }
    }

    &.ein-num {
      >.form-group {
        width: 55px;

        .form-control {
          min-width: inherit;
          text-align: center;
        }

        &:last-child {
          width: 110px;
        }
      }
    }
  }

  .has-disabled {
    cursor: not-allowed;
  }
}
