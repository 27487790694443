@import 'assets/scss/color-palette';

.editor {
  outline: none;
  margin-left: 35px;
  padding: 7px 35px 7px 10px;
  width: 335px;
  min-height: 34px;
  max-height: 210px;
  overflow-y: auto;
  border-radius: 4px;
  border: 1px solid $tower-gray;
  background-color: $snow-drift;
  color: $bright-gray;
  font-size: 14px;
  line-height: 17px;

  :global {
    .message-link {
      color: $bright-gray;
      text-decoration: underline;

      &:hover {
        color: $bright-gray;
        text-decoration: none;
      }
    }

    p.is-editor-empty:first-child::before {
      color: $regent-gray;
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }

  &:hover {
    border-color: $bright-gray !important;
  }

  &:focus {
    border: 1px solid $cerulean !important;
    box-shadow: 0 0 3px 2px rgba(3, 155, 230, 0.15);
  }

  &[contenteditable='false'] {
    background-color: $light-porcelain;
    cursor: not-allowed;

    &:hover {
      border: 1px solid $tower-gray !important;
    }

    &:focus {
      border: 1px solid $tower-gray !important;
      box-shadow: none !important;
    }
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $light-gray;
  }

  &::-webkit-scrollbar-thumb {
    background: $regent-gray;
  }
}
