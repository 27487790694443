@import "src/assets/scss/color-palette";

.wrapper {
  border-top: 1px solid $porcelain;
}

.statusWrapper {
  height: 35px;
  margin-top: 22px;
  padding: 0 1rem;
}

.scroll {
  padding-top: 15px;
  max-height: calc(100vh - 350px);
  width: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #ebeff2;
  }

  &::-webkit-scrollbar-thumb {
    background: #84939a;
  }
}

.table {
  margin-bottom: 0;

  tbody {
    tr {
      td {
        border: none;
        border-bottom: 1px solid #eceff1;
      }

      &:hover {
        td {
          background: $lily-white;
        }
      }
    }
  }
}