.add_button {
  cursor: pointer;

  i {
    color: #84939a;
    font-size: 18px;
    margin-right: 10px;
  }

  &:hover {
    i {
      color: #039be5;
    }

    span {
      text-decoration: none;
      background: none;
    }
  }

  &_disabled {
    pointer-events: none;
    cursor: not-allowed;

    i,
    span {
      color: #84939a;
    }

    span {
      background-image: linear-gradient(to right, #84939a 75%, transparent 75%);
    }
  }
}
