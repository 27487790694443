@import 'assets/scss/color-palette';

.progress {
  padding: 10px 25px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $lily-white;
  color: $bright-gray;
  position: absolute;
  bottom: 65px;
  left: 0;

  .icon {
    font-size: 16px;
    margin-right: 20px;
    color: $custom-blue;
  }

  .text {
    font-size: 14px;
    line-height: 1;
  }
}

.error {
  padding: 10px 25px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $fair-pink;
  color: $bright-gray;

  .icon {
    font-size: 16px;
    margin-right: 20px;
    color: $sunset-orange;
  }

  .text {
    font-size: 14px;
    line-height: 1;
  }
}
