.custom-dialogs {
  font-size: 16px;
  line-height: 21px;
  color: #353a45;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;

  .modal-header {
    background: #f5f6f6;
    border-radius: 5px 5px 0 0;
    padding: 8px 0 9px 10px;

    .modal-title {
      &.h4 {
        font-size: 16px;
        line-height: 21px;
        color: #353a45;
        font-weight: 400;
      }
    }

    &__close {
      color: #84939a;
      opacity: 1;
      padding: 0;
      background: transparent;
      border: none;

      &:hover {
        color: #039be5;
      }
    }
  }

  .modal-body {
    padding: 0;

    &__content {
      padding: 10px;
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border-top: 1px solid #e5e8e9;
    }
  }
}
