@import 'src/assets/scss/color-palette';

.modal {
  :global {
    .modal-content {
      min-width: 700px;
    }
  }

  .modalContentWrapper {
    padding: 20px;
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding-left: 20px;

    i {
      font-size: 20px;
    }
  }

  .info {
    display: flex;
    gap: 15px;
    padding: 15px 20px;
    background-color: $lily-white;
    border: 1px solid $light-porcelain;

    i {
      color: $cerulean;
    }

    span {
      color: $nevada;
    }
  }

  .warning {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 10px 20px;
    background-color: $fair-pink;
    border-radius: 5px;

    i {
      color: $sunset-orange;
    }
  }

  .sectionTitle {
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }

  .sectionSubTitle {
    padding-bottom: 20px;
    color: $nevada;
    font-style: italic;
  }

  .dataRow {
    display: flex;
    line-height: 20px;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  .rowLeft {
    min-width: 160px;
    font-weight: 600;
  }

  .rowRight {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .locationError {
    i {
      margin-right: 10px;
      font-size: 16px;
      color: $sunset-orange;
    }
  }

  .errorBgWithBorder {
    padding: 0 1px;
    border-radius: 3px;
    border: 1px solid $sunset-orange;
    background-color: $fair-pink;
  }

  .bgPink {
    padding: 1px 4px;
    background-color: $your-pink;
  }
}
