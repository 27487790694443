@import 'src/assets/scss/color-palette';

.input-time-component {
  color: $tuna;

  .iw-time_input {
    width: 100%;
    position: relative;
    text-align: left;
    background-color: $snow-drift;
    color: $nevada;
    box-sizing: border-box;
    border-radius: 4px;

    & > input,
    .form-control {
      position: relative;
      font-size: 16px;
      color: $tuna;
      z-index: 1;
      text-align: center;
      padding: 6px 36px !important;
      box-sizing: border-box;
      width: 100%;
      border-radius: 4px;
      border: 1px solid $alto;
      background-color: transparent;

      &:focus {
        border-color: $cerulean !important;
        box-shadow: none !important;
      }
    }

    .iw-arrow {
      position: absolute;
      top: 1px;
      bottom: 1px;
      z-index: 2;
      width: 32px;
      text-align: center;
      background-color: $porcelain;
      cursor: pointer;
      color: $regent-gray;

      &::before {
        font-family: FontAwesome, sans-serif;
        display: block;
        font-size: 18px;
        line-height: 1;
        width: 18px;
        height: 18px;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }

      &.iw-left_minus {
        left: 1px;
        border-radius: 4px 0 0 4px;
        border-right: 1px solid $alto;

        &::before {
          content: "\f104";
        }

        &.disabled {
          pointer-events: none;
        }
      }

      &.iw-right_plus {
        right: 1px;
        border-radius: 0 4px 4px 0;
        border-left: 1px solid $alto;

        &::before {
          content: "\f105";
        }

        &.disabled {
          pointer-events: none;
        }
      }

      &:hover {
        &::before {
          color: $cerulean;
        }
      }

      &:active {
        background-color: $tower-gray;

        &::before {
          color: $bright-gray;
        }
      }
    }
  }
}

.custom-time-input {
  &.input-time-component .iw-time_input > input,
  .input-time-component .iw-time_input .form-control {
    font-size: 14px;
    padding: 6px 27px !important;
  }

  &.input-time-component .iw-time_input .iw-arrow {
    width: 24px;
  }
}
