@import 'src/assets/scss/color-palette';

.icon {
  user-select: none;

  &:hover {
    cursor: pointer;
  }
}

.applied {
  i {
    color: $dodger-blue;
  }
}

