@import 'src/assets/scss/color-palette';

.wrap {
  .label{
    margin-bottom: 5px;
  }
  .elements {
    display: inline-flex;
    position: relative;
  }

  .item {
    min-width: 100px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    border-radius: 5px 0 0 5px;
    background: $porcelain;
    cursor: pointer;
    padding: 0 12px;
    margin-right: 2px;

    &:nth-child(2) {
      border-radius: 0 5px 5px 0;
    }

    &:hover {
      background:  $alto;
    }
  }

  .checked {
    background: $jungle-green;
    color: white;

    &:nth-child(2) {
      background: $sunset-orange;
    }

    &:hover {
      background: $jungle-green;

      &:nth-child(2) {
        background: $sunset-orange;
      }
    }
  }

  .error {
    border: 1px solid $sunset-orange;
  }
}
