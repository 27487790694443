@import 'src/assets/scss/color-palette';

.wrap {
  position: fixed;
  z-index: 1000;
  top: 60px;
  width: 500px;
  right: 0;
  height: calc(100vh - 60px);
  background: #fff;
  box-shadow: 0 0 10px #26323840;
  border-radius: 10px 0 0 0;
  overflow: hidden;
}

.header {
  min-height: 60px;
  background: #f7f9f9;
  padding: 15px 20px;
  color: #2f333e;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    font-size: 20px;
  }

  .close {
    color: #84939a;
    font-size: 18px;
    cursor: pointer;

    &:hover {
      color: #039be5;
    }
  }
}
