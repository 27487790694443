.wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 1rem;
}

.distanceWrapper {
    margin-top: 20px;
}
