.list_group{
  border-radius: unset;
  .list_group_item{
    border-color: #ECEFF1;
    border-style: solid ;
    border-width: 1px 1px 0 1px;
  }
  .disabled{
    background: #ECF9FF;
  }
  .wrapper{
    position: relative;
    display: flex;
    align-items: center;
    .title{
      color: #2F333E;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
    .date{
      color: #B0BEC6;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      padding-left: 8px;
    }
    .close_button{
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .message{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #2F333E;
    padding: 5px 0;
  }
}