.modal{

}

.form {
  :global {
    .count-brief-comment {
      font-size: 14px;
      color: #84939a;
      text-align: right;
      margin-top: -10px;
    }
  }
}
