@import 'color-palette';

.archived-entity {
  color: $regent-gray;

  &::before {
    font-family: "FontAwesome", sans-serif;
    font-weight: 400;
    font-size: 14px;
    content: "\f187";
    padding-right: 5px;
  }
}
