@import "src/assets/scss/color-palette";

.wrap {
  padding: 20px 20px 0 20px;
}

.form {
  display: flex;
  align-items: flex-end;
  grid-gap: 10px;
  width: 100%;
  > div:nth-child(1) {
    width: 40%;
  }
}

.location {
  background: $lily-white;
  padding: 10px 20px;
  margin: 0 0 20px;

}
