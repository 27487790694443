@import 'src/assets/scss/color-palette';

$light-border: lighten($sunset-orange, 20%);

.tooltip-custom-bottom,
.tooltip-custom-top,
.tooltip-select-bottom,
.tooltip-select-top,
.tooltip-chat-top,
.tooltip-checkbox-top {
  .close-tooltip {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;

    .fa {
      color: #fff;
    }
  }
}

.tooltip-custom-bottom {
  z-index: 301;
  display: inline-block;
  position: absolute;
  border: 1px solid #ff5253;
  text-decoration: none;
  border-radius: 3px;
  padding: 5px 5px 5px 5px;
  color: #fff;
  background-color: #ff5253;
  top: 100%; /* tooltip placement */
  margin-top: 2px;
  right: 0;
  line-height: 1.3;
  font-weight: 400;
  font-size: 14px;
}

.tooltip-custom-top {
  z-index: 1;
  display: inline-block;
  position: absolute;
  border: 1px solid #ff5253;
  text-decoration: none;
  border-radius: 3px;
  padding: 5px 25px 5px 5px;
  color: #fff;
  background-color: #ff5253;
  right: 0;
  bottom: 38px;
  line-height: 1.3;
  font-weight: 400;
  font-size: 14px;
}

.tooltip-checkbox-top {
  z-index: 1;
  display: inline-block;
  position: absolute;
  border: 1px solid #ff5253;
  text-decoration: none;
  border-radius: 3px;
  padding: 5px 25px 5px 5px;
  color: #fff;
  background-color: #ff5253;
  right: auto;
  left: 0;
  bottom: 30px;
  line-height: 1.3;
  font-weight: 400;
  font-size: 14px;
}

.tooltip-select-bottom {
  z-index: 1;
  display: inline-block;
  position: absolute;
  border: 1px solid #ff5253;
  text-decoration: none;
  border-radius: 3px;
  padding: 5px 25px 5px 5px;
  color: #fff;
  background-color: #ff5253;
  top: 100%; /* tooltip placement */
  margin-top: 2px;
  right: 0;
  line-height: 1.3;
  font-weight: 400;
  font-size: 14px;
}

.tooltip-select-top {
  z-index: 998;
  display: inline-block;
  position: absolute;
  border: 1px solid #ff5253;
  text-decoration: none;
  border-radius: 3px;
  padding: 5px 25px 5px 5px;
  color: #fff;
  background-color: #ff5253;
  right: 16px;
  bottom: 53px;
  line-height: 1.3;
  font-weight: 400;
  font-size: 14px;
}

.tooltip-chat-top {
  z-index: 999;
  display: inline-block;
  position: absolute;
  border: 1px solid #ff5253;
  text-decoration: none;
  border-radius: 3px;
  padding: 5px 25px 5px 5px;
  color: #fff;
  background-color: #ff5253;
  left: 0;
  bottom: 72px;
  line-height: 1.3;
  font-weight: 400;
  font-size: 14px;
}
