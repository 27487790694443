.dropdown-radio-input {
  .iw_rb_shell.iw_rb_horisontal {
    display: block;

    & > div {
      margin-left: 0;
      display: inherit;
      cursor: pointer;
      white-space: nowrap;
    }
  }
}
