.container {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  border-radius: 3px;
  padding: 0 3px;

  &.column_direction {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &.zero_padding {
    padding: 0;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.with_last_space {
  > span {
    padding-right: 5px;
  }
}

.skip_last_space {
  > span:not(:last-child) {
    padding-right: 5px;
  }
}