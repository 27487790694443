.controls {
  display: flex;
  flex-direction: column;
  line-height: 12px;

  .controlBtn {
    width: auto;
    height: auto;
    min-width: auto;
    margin: 0;
    padding: 0 5px;
    background-color: transparent;
    opacity: 0.5;

    :hover {
      opacity: 1;
    }

    i {
      font-size: 14px;
      margin: 0;
      padding: 0;
    }
  }
}
