@import 'assets/scss/color-palette';

.tooltip {
  opacity: 1 !important;
  z-index: 2000 !important;

  :global {
    .tooltip-inner {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      max-width: none;
      border: 1px solid rgba($yellow-orange, 0.2);
      background-color: $seashell-peach;
      font-size: 14px;
      color: $tuna;
    }
  }

  &[x-placement="top"] {
    :global {
      .arrow {
        &::before {
          border-top-color: $sunglow;
        }
      }
    }
  }

  &[x-placement="bottom"] {
    :global {
      .arrow {
        &::before {
          border-bottom-color: $sunglow;
        }
      }
    }
  }

  &[x-placement="left"] {
    :global {
      .arrow {
        &::before {
          border-left-color: $sunglow;
        }
      }
    }
  }

  &[x-placement="right"] {
    :global {
      .arrow {
        &::before {
          border-right-color: $sunglow;
        }
      }
    }
  }
}
