.originDestinationWrapper {
  margin-top: 26px;
}

.payloadWrapper {
  margin-top: 26px;
}

.truckWrapper {
  margin-top: 22px;
  margin-bottom: 22px;
}
