@import 'src/assets/scss/color-palette';

.text-input-blockable-wrap {
  position: relative;

  .edit-icon {
    color: $regent-gray;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
    padding: 10px;

    &:hover {
      color: $cerulean;
    }
  }

  &.redesign {
    .edit-icon {
      font-size: 14px;
      padding: 6px;

      &:hover {
        color: $bright-gray;
      }
    }
  }
}
