.standard-search-filter {
  .filter-form {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(38, 50, 56, 0.05);
    margin-bottom: 20px;

    &__header {
      align-items: center;
      background: #f7f9f8;
      border-bottom: 1px solid #ebeff2;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      display: flex;
      justify-content: space-between;
      padding: 20px;

      .title {
        color: #424242;
        font-size: 20px;
        font-weight: 400;
        line-height: 23px;

        .desc {
          color: #84939a;
          display: block;
          font-size: 14px;
        }
      }

      button {
        font-size: 20px;

        &:focus {
          outline: none;
        }
      }
    }

    &__main {
      background: #fff;

      .filter-item {
        .iw_cb_shell {
          margin: 30px 0 0;

          span.cb_square {
            margin-right: 10px;
          }

          label {
            font-weight: 400;
            margin-right: 15px;
          }
        }

        &__title {
          align-items: center;
          border-bottom: 1px solid #ebeff2;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          padding: 10px 20px;

          h4 {
            border-bottom: 1px solid #2f333e;
            color: #2f333e;
            font-size: 16px;
            font-weight: 500;
            padding-bottom: 1px;
          }

          button {
            font-size: 20px;

            &:focus {
              outline: none;
            }
          }
        }

        &__content {
          border-bottom: 1px solid #ebeff2;
          padding: 20px;

          &_info {
            background-color: #f7f9f8;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
            margin-top: 25px;
            padding: 20px 25px 20px 40px;
            position: relative;

            &::before {
              color: #84939a;
              content: '\f129';
              font-family: FontAwesome, sans-serif;
              font-size: 18px;
              left: 20px;
              position: absolute;
              top: 20px;
            }

            strong {
              font-weight: 500;
            }
          }

          .form-group {
            label {
              color: #363a45;
              font-size: 14px;
              font-weight: 400;
              text-transform: uppercase;
            }

            .iw_rb_shell {
              label {
                cursor: pointer;
                font-size: 15px;
                font-weight: 400;
                letter-spacing: -0.3px;
                line-height: 20px;
                text-transform: none;
                -webkit-touch-callout: none;
                user-select: none;
              }
            }

            .iw_cb_shell {
              margin: 5px 0 10px;

              label {
                cursor: pointer;
                font-size: 15px;
                font-weight: 400;
                letter-spacing: -0.3px;
                line-height: 20px;
                text-transform: none;
                -webkit-touch-callout: none;
                user-select: none;
              }
            }
          }

          .form-group-inline {
            display: flex;
            justify-content: space-between;

            .form-group {
              margin-left: 5px;
              margin-right: 5px;

              &:first-child {
                margin-left: 0;
              }

              &:last-child {
                margin-right: 0;
              }
            }

            &.fw60 {
              .form-group {
                &:nth-child(1) {
                  width: 60%;
                }

                &:nth-child(2) {
                  width: 40%;
                }
              }
            }
          }

          .input-group-inline {
            align-items: center;
            display: flex;
            justify-content: space-between;

            .overlay-trigger-wrap {
              width: 100%;
            }

            .form-group {
              display: flex;
              margin: 0 5px;

              &:first-child {
                margin-left: 0;
              }

              &:last-child {
                margin-right: 0;
              }
            }

            >div {
              margin: 0 5px;

              &:first-child {
                margin-left: 0;
                width: 50%;
              }

              &:last-child {
                margin-right: 0;
                width: 50%;
              }

              .form-group.has-feedback > div {
                width: 100%;
              }
            }

            >.form-control {
              margin: 0 5px;

              &:first-child {
                margin-left: 0;
              }

              &:last-child {
                margin-right: 0;
              }
            }

            span {
              font-size: 14px;
              margin: 0 5px;
              z-index: 0;
            }
          }

          .form-control-feedback {
            color: #84939a;
            padding-top: 9px;
          }
        }
      }
    }

    &__footer {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 20px;
    }
  }
}

.filter-alerts {
  margin: 20px 0 15px;

  .alert {
    align-items: center;
    background: #fff;
    border: none;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(38, 50, 56, 0.05);
    color: #363a45;
    display: inline-flex;
    font-size: 14px;
    justify-content: space-between;
    line-height: 20px;
    margin: 0 5px 5px 0;
    min-width: 70px;
    padding: 0;
    text-transform: capitalize;

    &__title {
      padding: 4px 5px 4px 12px;
    }

    &__remove {
      font-size: 14px;
      line-height: 20px;
      padding: 4px 12px 4px 7px;

      &:focus {
        outline: none;
      }
    }
  }
}

.filter-result {
  margin-bottom: 20px;

  .alert {
    background: #fff;
    border: none;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(38, 50, 56, 0.05);
    font-size: 16px;
    padding: 20px;
  }
}
