.list {
  border: 1px solid #ebeff2;
  border-radius: 5px;
  margin-top: 20px;

  :global {
    .file-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ebeff2;

      &:last-child {
        border-bottom: 0;
      }

      &__title {
        color: #2f333e;
        font-weight: 300;
        width: calc(100% - 150px);
        padding: 11px;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__badge {
        padding: 5px;
        display: flex;
        align-items: center;

        .file-badge {
          border: 1px solid #ebeff2;
          border-radius: 4px;
          background-color: #ebeff2;
          font-size: 14px;
          padding: 4px 7px;
          margin-right: 5px;
          white-space: nowrap;
          line-height: 1;
        }
      }
    }
  }
}
