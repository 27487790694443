@import "src/assets/scss/color-palette";

.wrapper {
  display: flex;
  gap: 10px;
  color: $regent-gray;
  border-bottom: 1px solid $porcelain;
  padding-bottom: 26px;
}

.infoIcon {
  width: 24px;
  height: 24px;
  background-color: $lily-white;
  text-align: center;
  border-radius: 4px;
  margin-left: 80px;
  > i {
    color: $cerulean;
    font-size: 14px;
  }
}

.dimsWrapper {
  display: flex;
  gap: 5px;
}
