@import 'src/assets/scss/color-palette';

.add_note {
  padding: 0 20px 0 20px;

  :global {
    .form-label {
      text-transform: none;
    }
  }
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 14px;

  :global {
    .iw_cb_shell {
      margin: 0;

      span.cb_square {
        margin: 0 10px 0 0;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
  }
}

