@import 'src/assets/scss/color-palette';

.wrap {
  position: relative;
  display: inline-block;
  width: 130px;
}

.input {
  position: relative;
}

.input_has_error {
  input {
    border-color: $sunset-orange !important;
    background-color: #ffeded !important;
  }
}

.single {
  input {
    border-radius: 0.25rem !important;
  }
}

.inputs {
  display: flex;
  width: 100%;

  input {
    min-width: 50%;
  }

  div:first-child input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  div:last-child input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
