@import 'assets/scss/color-palette';

.reserve {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 20px;
  color: $tuna;
  background-color: $light-gray;
  border-bottom-right-radius: 5px;

  &.myself {
    background-color: $dodger-blue;
    color: $white;
  }

  .content {
    padding: 2px 11px;
  }
}
