@import 'color-palette';

.infowin_sides_wr {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .infowin_side {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.infowin_title_wr {
  position: relative;
  background-color: $snow-drift;
  border-radius: 5px 5px 0 0;

  h3 {
    margin: 0 0;
  }
}
