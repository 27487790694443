@import 'assets/scss/color-palette';

.mark {
  font-size: 16px;
  color: $cerulean;
  margin-right: 5px;
}

:global {
  [class*='option--is-selected'] {
    span {
      color: $white;
    }
  }
}
