.modal {
  :global {
    .modal-body {
      &__email-from {
        padding: 10px 20px 0;
        border-bottom: 1px solid #ebeff2;
      }

      &__email-to {
        padding: 10px 20px 0;
        font-size: 14px;
      }

      &__fields {
        padding: 10px 20px;
      }
    }
  }
}
