@import 'src/assets/scss/color-palette';

.icon-gray {
  color: $regent-gray;
}

.icon-yellow {
  color: $yellow-orange;
}

.icon-green {
  color: $shamrock;
}

.icon-blue {
  color: $cerulean;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
