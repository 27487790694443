@import 'assets/scss/color-palette';

.container {
  max-width: fit-content;
  position: relative;

  :global {
    i.fa-copy {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      color: $regent-gray;
      background-color: $white;
      transition: background-color 0.1s;

      &:hover {
        background-color: $light-gray;
      }
    }

    i.fa-check {
      margin-right: 5px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      color: $jungle-green;
    }
  }
}

.wrap {
  padding-left: 10px;
  display: flex;
  align-items: center;
  position: absolute;
  width: max-content;
  height: 20px;
  top: 0;
  left: 100%;
  background-color: transparent;
  text-wrap: nowrap;
  visibility: hidden;
  z-index: 1;

  .copy {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover + div {
      visibility: visible;
    }
  }

  .tooltip {
    margin-left: 5px;
    height: 24px;
    padding: 4px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    border-radius: 3px;
    border: 1px solid $yellow-orange;
    background-color: $seashell-peach;
    visibility: hidden;
  }
}

.visible {
  visibility: visible;
}

.copied {
  color: $jungle-green;
}
