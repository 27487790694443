@import 'assets/scss/color-palette';

.form {
  width: 100%;

  .body {
    padding: 0 15px 15px 15px;
    position: relative;
  }

  .button__search {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    &:disabled,
    &:hover:disabled,
    &:active:disabled {
      background-color: transparent;

      i {
        color: transparent;
      }
    }
  }

  :global {
    .form-control {
      resize: none;
      overflow: auto;
      min-height: 36px;
      height: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: $light-gray;
      }

      &::-webkit-scrollbar-thumb {
        background: $regent-gray;
      }
    }

    .form-group {
      margin-bottom: 0;
      width: 100%;
      margin-left: 40px;
    }
  }
}
