.wrapper {
  background-color: #f7f9f8;
  padding-top: 1rem;
  padding-left: 1rem;
}

.label {
  flex: 1;
  font-size: 18px;
  line-height: 1.9;
}

.expand {
  display: flex;
  justify-content: space-between;
}

.info {
  color: grey;
  margin-right: 4px;
}
