.settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 0 10px;

  &__selected {
    &__all_visible {
      padding-left: 45px;
      color: #f57f17;
    }

    &__all_exists {
      padding-left: 45px;
      color: #27af6d;
    }
  }
}
