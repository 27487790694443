.custom-scroll {
  max-height: calc(100vh - 250px);
  width: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #ebeff2;
  }

  &::-webkit-scrollbar-thumb {
    background: #84939a;
  }
}

.popover-custom-scroll {
  max-height: calc(100vh - 300px);
  width: auto;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #ebeff2;
  }

  &::-webkit-scrollbar-thumb {
    background: #84939a;
  }
}
