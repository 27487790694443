@import 'src/assets/scss/color-palette';

.truck-reserving-modal {
  color: $tuna;

  button:disabled {
    cursor: not-allowed;
  }

  h4 {
    font-size: 20px;
  }

  .small-text {
    color: $regent-gray;
  }

  .modal-content {
    min-width: 700px;
  }

  .modal-header {
    background-color: $snow-drift;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .modal-footer {
    display: flex;
  }

  .modal-close {
    font-size: 20px;
    color: $tower-gray;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 30px;
    cursor: pointer;
    background-color: $snow-drift;
    border: none;
  }

  .dispatcher-note {
    background-color: $snow-drift;
    border: 1px solid $porcelain;
    border-radius: 3px;
    width: 100%;
    padding: 25px;
    margin-bottom: 12px;
  }

  .note-item,
  .note-item-inline {
    word-break: break-all;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

    .description {
      position: relative;
      color: $regent-gray;
      padding-left: 10px;
      display: inline-block;

      .second-link {
        text-decoration: none;
        background-image: linear-gradient(to right, $regent-gray 75%, transparent 75%);
        background-position: 0 95%;
        background-repeat: repeat-x;
        background-size: 7px 1px;
        color: $regent-gray;
      }

      .second-link:hover,
      .second-link:focus {
        color: $regent-gray;
        text-decoration: none;
        background: none;
      }

      &::before {
        content: '';
        display: inline-block;
        height: 10px;
        border-left: 1px dotted $regent-gray;
        position: absolute;
        bottom: 5px;
        left: 4px;
      }
    }
  }

  .note-item-inline {
    display: inline-block;
    float: right;

    .description::before {
      display: none;
    }
  }

  .form-buttons {
    display: flex;
    margin-top: 15px;
  }

  .reserve-btn-container {
    margin-left: auto;

    .button:last-child {
      margin-left: 10px;
    }
  }

  .reserve_action {
    background-color: $light-gray;
    border: 1px solid $light-gray;
    padding: 7px 15px;
    text-transform: uppercase;

    .form-group {
      margin-bottom: 0;
    }
  }

  .reserve_select {
    padding: 15px 15px 0;
  }

  .modal-body {
    &__not-reserve {
      padding: 20px;
      border-bottom: 1px solid #ebeff2;
    }

    &__reserve {
      padding: 20px;
      border-bottom: 1px solid #ebeff2;
    }

    &__notes {
      padding: 20px;
    }

    > *:last-child {
      border-bottom: none;
    }
  }

  &.step1 {
    .modal-body {
      &__notes {
        padding: 20px 20px 0 20px;
      }
    }
  }

  .reserving-label {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
  }

  .reserving-data {
    display: inline-flex;
    grid-gap: 5px;
  }
}
