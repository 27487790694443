@import 'assets/scss/color-palette';

.wrap {
  position: relative;

  :global {
    .form-control {
      padding-left: 35px;
      font-size: 16px;
    }
  }
}

.button__search {
  position: absolute;
  top: 0;
  left: 5px;
  right: inherit;
  z-index: 10;
  width: 28px;
  height: 34px;
  border: none;
  background-color: transparent;

  i {
    color: $regent-gray;
    font-size: 16px;
    transition: color 0.2s ease;
  }

  &:hover {
    i {
      color: $cerulean;
    }
  }
}

.button__clear {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;

  button {
    width: 30px;
    height: 34px;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }
}
