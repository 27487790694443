.profile-photo {
  height: 220px;

  &__item {
    width: 100%;
    min-height: 185px;
    height: calc(100% - 25px);
    padding: 30px;
    background-color: #ebeff2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    p {
      text-transform: uppercase;
      color: #84939a;
      text-align: center;
    }
  }
}
