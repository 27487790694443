@import "src/assets/scss/color-palette";

.wrap {
  margin: 0 20px 10px;
  padding: 10px 20px;
  border-radius: 3px;
  background: $snow-drift;
  line-height: 1.2;


  .title {
    color: $regent-gray;
    margin-bottom: 5px;
  }

  .main {
    display: flex;
    align-items: flex-start;
    width: 100%;
    .location {
        width: 60%;
    }
    .check {
      width: 40%;
      >div {
        margin-bottom: 5px;
        label {
          text-transform: none;
          font-size: 16px;
        }
      }
    }
  }

  .distance {
    &.behind {
      color: $green-haze;
    }
    &.ahead {
      color: $jungle-orange;
    }
  }
}