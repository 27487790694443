@import 'src/assets/scss/color-palette';

.scroll {
  padding-bottom: 15px;
  max-height: calc(100vh - 350px);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #ebeff2;
  }

  &::-webkit-scrollbar-thumb {
    background: #84939a;
  }
}

.number_wrap {
  display: flex;
}

.table {
  tbody {
    tr {
      td {
        width: 24%;
        border: none;
        border-bottom: 1px solid #eceff1;

        &:last-child {
          width: 5%;
        }
      }

      &:hover {
        td {
          background: $lily-white;
        }
      }
    }
  }
}
