@import 'assets/scss/color-palette';

.wrap {
  .empty {
    margin: 20px auto 0;
    font-style: italic;
    line-height: 19px;
    text-align: center;
    color: $tower-gray;
  }

  .item {
    margin-right: 20px;
    max-width: 220px;
    width: 100%;

    &:not(:nth-child(-n + 4)) {
      margin-top: 20px;
    }
  }

  .scroll {
    display: flex;
    flex-wrap: wrap;
    max-height: calc(100vh - 340px);
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: $porcelain;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: $snow-drift;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: $tower-gray;
    }
  }

  :global {
    .tab-content {
      padding: 20px 10px 0px 20px;
      height: calc(100vh - 300px);
    }

    .nav-tabs {
      padding: 0;
      margin-top: -38px;
      background-color: transparent;
      justify-content: flex-end;
      border-bottom: 2px solid $light-gray;

      .nav-link {
        padding: 6px 15px;
        margin: 0 2px;
        border: 2px solid $light-gray;
        background-color: $light-gray;
        border-bottom: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin-bottom: -2px;

        &:hover {
          border-color: transparent;
        }

        &:last-child {
          margin-right: 20px;
        }

        &.active {
          border: 2px solid $light-gray;
          background-color: $white;
          border-bottom: 0;
        }
      }
    }
  }
}
