.item {
  border: 1px solid #eaeef1;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;

  :global {
    .invoices-list {
      &__header {
        background: #f6f9f8;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 10px 15px;
        cursor: pointer;

        &:hover {
          background: #eaeef1;
        }

        .address {
          line-height: 20px;
        }
      }

      &__content {
        display: none;

        &_descr {
          background: #f6f9f8;
          padding: 10px 15px;
        }
      }

      &.open {
        .invoices-list__header {
          background: #eaeef1;
        }

        .invoices-list__content {
          display: block;
        }
      }
    }
  }
}

.list {
  border-bottom: 1px solid #eaeef1;
  padding: 10px 15px;

  &:last-child {
    border: none;
  }

  :global {
    .invoice-item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &__title {
        .iw_cb_shell {
          margin: 0;

          label {
            width: 100%;
            line-height: 30px;
            margin-left: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-transform: none;
            font-size: 16px;
          }
        }
      }
    }
  }
}
