.custom-lightbox {
  &.rotated0 {
    padding: 100px;
    .yarl__slide_wrapper img {
      transform: rotate(0deg);
    }
  }

  &.rotated90 {
    .yarl__slide_wrapper img {
      transform: rotate(90deg);
    }
  }

  &.rotated180 {
    .yarl__slide_wrapper img {
      transform: rotate(180deg);
    }
  }

  &.rotated270 {
    .yarl__slide_wrapper img {
      transform: rotate(270deg);
    }
  }

  .yarl__container {
    background-color: rgba(0, 0, 0, .85);
  }

  .yarl__toolbar {
    height: 60px;
  }

  .yarl__slide_title {
    padding-left: 70px;
    font-size: 16px;
    line-height: 32px;
  }

  .yarl__button {
    &.rotateButton {
      width: 44px;

      i {
        padding-top: 2px;
        font-size: 22px;
      }
    }

    &.yarl__navigation_prev,
    &.yarl__navigation_next {
      svg {
        width: 64px;
        height: 64px;
      }
    }
  }
}
