.wrap {
  padding: 5px 20px 40px;
  position: relative;

  .button {
    position: absolute;
    top: 8px;
    right: 20px;
  }

  :global {
    .form-group {
      margin-bottom: 0;
    }
  }
}
