@import "src/assets/scss/color-palette";

.content {
  padding: 20px;

  .emails {
    background: $lily-white;
    border-radius: 5px;
    padding: 10px 20px;
    margin-bottom: 20px;
  }

  .sendData {
    display: flex;
    justify-content: space-between;

    .message {
      width: 90%;

      .subject {
        margin-bottom: 5px;
      }

      .text {
        white-space: pre-line;
      }
    }
  }
}
