@import "color-palette";

.standard-table-style {
  .table {
    thead {
      border-bottom: 1px solid #eceff1;
      color: #84939a;
      text-transform: uppercase;

      tr {
        th {
          background-color: #f7f9f8;
          border: none;
          font-size: 14px;
          font-weight: 500;
          padding: 20px 15px;
          vertical-align: middle;

          &:first-child {
            border-radius: 5px 0 0;
          }

          &:last-child {
            border-radius: 0 5px 0 0;
            padding: 10px 15px;
          }
        }
      }
    }

    tbody {
      background: #fff;
      color: #2f333e;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;

      tr {
        &.no-info-table {
          td {
            padding: 50px;
            text-align: center;

            h2 {
              font-size: 20px;
              line-height: 1;
              margin-bottom: 20px;
            }
          }
        }

        td:not(.empty) {
          border: 1px solid #eceff1;
          padding: 10px 15px;
          position: relative;
          vertical-align: middle;
          line-height: 1.2;
          word-break: break-all;

          .table-pic {
            align-items: center;
            background-color: #ebeff2;
            color: #84939a;
            display: flex;
            font-size: 14px;
            height: 20px;
            justify-content: center;
            left: 0;
            position: absolute;
            top: 0;
            width: 20px;

            @media only screen and (max-width: 1200px) {
              font-size: 10px;
              height: 12px;
              width: 12px;
            }
          }

          .table-link-arrow {
            position: relative;

            &::before {
              box-shadow: none;
              color: #b0bfc6;
              content: '\f178';
              display: inline-block;
              font-family: FontAwesome, sans-serif;
              font-size: 14px;
              position: absolute;
              right: -25px;
              top: 1px;
            }
          }

          .btn-table {
            cursor: pointer;
            align-items: center;
            background-color: #ebeff2;
            border: none;
            border-radius: 4px;
            box-shadow: none;
            color: #84939a;
            display: flex;
            height: 34px;
            justify-content: center;
            margin: 0 auto;
            width: 34px;

            &:hover,
            &:focus {
              color: #039be5;
              outline: none;
            }

            &__light {
              background-color: transparent;
              cursor: pointer;

              &:disabled {
                cursor: default;
              }
            }

            &__list {
              background-color: transparent;
              flex-direction: column;

              span {
                background-color: #ebeff2;
                border-radius: 4px;
                display: block;
                height: 4px;
                width: 20px;
              }
            }
          }

          .btn-flex {
            display: flex;
            justify-content: space-between;
            margin: 5px 0;

            &__item {
              font-size: 16px;
              margin-right: 5px;

              &:last-child {
                font-size: 20px;
                margin: 0;
              }
            }
          }

          &:first-child {
            border-left: 0;
          }

          &:last-child {
            border-right: 0;
          }

          .pic-right-block {
            position: absolute;
            top: -1px;
            right: 3px;
            font-size: 14px;
            line-height: 20px;
            color: #363a45;
          }

          &.ellipsis {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        &.archived {
          td:not(.view-link-td) {
            color: #84939a;

            .fa {
              color: #b0bfc6;
            }

            > a,
            span {
              color: #84939a;

              &.second-link {
                border-bottom: none;
                background-image: none;
                cursor: not-allowed;
                pointer-events: none;
              }

              &.link-archived {
                border-bottom: 1px dashed transparent;
                background-image: none;
                cursor: not-allowed;
                pointer-events: none;
              }
            }

            .button {
              .fa {
                color: #84939a;
              }

              &:hover {
                .fa {
                  color: #039be5;
                }
              }

              &.button-archived {
                cursor: not-allowed;
                pointer-events: none;
                background: #f7f9f8;
                color: #84939a;

                .fa {
                  color: #b0bfc6;
                }
              }

              &[disabled] {
                .fa {
                  color: #b0bfc6;
                }

                &:hover {
                  .fa {
                    color: #b0bfc6;
                  }
                }
              }
            }
          }
        }
      }

      tr.auction-quote-offer-row {
        td:not(.empty) {
          border: 1px solid #dae2e7;
        }
      }
    }
  }

  .table-row-hover tbody tr:hover td:not(.empty),
  .table-row-hover tbody tr:hover th {
    background-color: $snow-drift;
  }

  .table-row-hover {
    tbody {
      background-color: $white;
    }
  }
}
