@import "src/assets/scss/color-palette";

.steps {
  padding: 20px;
  display: flex;
  grid-gap: 20px;
  *:not(:last-child){
    &:after {
      font-family: FontAwesome, sans-serif;
      color: $tower-gray;
      padding-left: 20px;
      content: '\f105';
    }
  }
}

.step {
  font-weight: 600;
  color: $bright-gray;

  &__completed {
    font-weight: 600;
    color: $regent-gray;
  }

  &__disabled {
    color: $regent-gray;
    font-weight: 400;
  }
}

