.form {
  padding: 20px;
  display: flex;
  max-width: 800px;
  width: 100%;

  :global {
    .form-group {
      margin: 0;
    }
  }
}
