.modal {
  .warning {
    background: #fdeded;
    padding: 15px;
    display: flex;

    i {
      font-size: 20px;
      color: #ff5253;
      margin-right: 10px;
      margin-top: 5px;
    }
  }

  .main {
    padding: 15px;

    >div:first-child {
      width: 70%;
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 20px;
    border-top: 1px solid #ebeff2;
  }

  :global {
    .modal-header {
      width: 100%;
      padding: 10px 15px;
      background: #f7f9f9;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .modal-title {
        color: #2f333e;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.4;
      }
    }

    .modal-body {
      padding: 0;
    }
  }
}
