.item {
  background-color: #ecf9ff;
  padding: 10px 50px 10px 20px;
  border-top: 1px solid #ebeff2;
  position: relative;

  :global {
    .tooltip-expand {
      position: absolute;
      right: 15px;
      top: 5px;
    }

    .summary-group {
      display: none;
      justify-content: flex-end;
      margin-bottom: 10px;

      .total-miles {
        width: 340px;
        display: flex;
        justify-content: flex-end;

        .data {
          margin-left: 20px;
          padding-left: 10px;
          width: 80px;
        }
      }

      .total-payment {
        margin-bottom: 10px;
        width: 265px;
        display: flex;
        justify-content: flex-end;

        .title {
          width: 40%;
        }

        .data {
          margin-left: 20px;
          width: 50%;

          div {
            display: flex;
            justify-content: space-between;

            .reim {
              position: relative;

              &::before {
                position: absolute;
                left: -10px;
                content: '+';
              }
            }

            .ded {
              position: relative;

              &::before {
                position: absolute;
                left: -10px;
                content: '-';
              }
            }
          }
        }
      }

      &.open {
        display: flex;
      }
    }

    .summary-total {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__title {
        color: #039be6;
      }

      &__data {
        display: flex;
        align-items: center;
        font-weight: 700;

        .data {
          font-size: 20px;
          margin-left: 20px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
