
// ---------- RADIO BOXES
.iw_rb_shell {
  &.iw_rb_horisontal {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;

    .rb_square {
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: middle;
      position: relative;
      border-radius: 50%;
      background-color: #f7f9f8;
      border: 1px solid #b0bfc6;

      &::before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: transparent;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }

    label {
      cursor: pointer;
      vertical-align: middle;
      display: inline-block;
      margin-bottom: 0;
      text-transform: none;
      margin-left: 10px;
      font-weight: 400;
      font-size: 16px;
    }

    & > div {
      margin-left: 10px;
      display: inline-block;
      cursor: pointer;

      &:first-of-type {
        margin-left: 0;
      }

      &.iw_rb_checked {
        .rb_square {
          &::before {
            background-color: #363a45;
          }
        }
      }

      &:hover {
        .rb_square {
          border-color: #363a45;
        }
      }

      &.disabled {
        pointer-events: none;
        cursor: not-allowed;
        color: #b0bfc6;

        .rb_square {
          background-color: #ebeff2;
        }
      }
    }
  }
}
