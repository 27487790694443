@import 'src/assets/scss/color-palette';

.info-modal {
  .feedback-icon-success {
    color: $jungle-green;
    font-size: 24px;
    vertical-align: middle;
    margin: 5px 10px;
  }

  .feedback-icon-error {
    color: $sunset-orange;
    font-size: 24px;
    vertical-align: middle;
    margin: 5px 10px;
  }

  .feedback-icon-reset {
    color: $yellow-orange;
    font-size: 24px;
    vertical-align: middle;
    margin: 5px 10px;
  }

  .feedback-icon-warning {
    color: $orange;
    font-size: 24px;
    vertical-align: middle;
    margin: 5px 10px;
  }

  .modal-header {
    background-color: $snow-drift;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .modal-title {
    font-size: 20px;
    font-weight: 400;
  }

  .modal-subtitle {
    display: block;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 400;
  }

  .attention {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: $sunset-orange;
    padding: 0;
    margin: 0 0 10px 0;
  }

  &__text {
    line-height: 50px;
  }

  .flex-block {
    align-items: center;
  }

  &__successful-attachment {
    p {
      line-height: 44px;
    }
  }
}
