.notification-widget {
  position: relative;

  .notification-bar-btn {
    position: relative;

    .fa {
      font-size: 18px;
    }

    .notification-count-message {
      position: absolute;
      right: 3px;
      top: 10px;
      display: inline-block;
      text-align: center;
      border-radius: 10px;
      height: 20px;
      min-width: 20px;
      padding: 0 2px;
      background-color: #00af41;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .notification-menu {
    position: absolute;
    background-color: #fff;
    top: 60px;
    right: 0;
    color: #84939a;
    height: auto;
    width: 400px;
    max-height: 0;
    transition: all 0.45s ease-out;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);

    &.active {
      overflow: visible;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: #40C4FF;
      border: unset;
    }
  }
}