.list {
  background: #eceff1;
  border-radius: 4px;
  color: #84939a;
  display: block;
  font-size: 14px;
  font-weight: 300;
  height: 34px;
  line-height: 34px;
  margin-right: 15px;
  text-align: center;
  width: 34px;
  min-width: 34px;
  cursor: pointer;

  :global {
    img {
      border-radius: 4px;
      height: 34px;
      width: 34px;
      cursor: pointer;
      object-fit: cover;
      display: block;
    }
  }
}

.grid {
  :global {
    .file-preview {
      background: #eceff1;
      color: #84939a;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 300;
      border-top-right-radius: 2px;
      border-top-left-radius: 2px;
      position: relative;
      height: calc(110px - 41px);
      min-width: 106px;

      &__hover {
        background: rgba(#353a45, 0.6);
        width: 106px;
        height: 67px;
        position: absolute;
        top: 0;
        z-index: 100;
        opacity: 0;
        transition: all 0.3s;
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
      }

      &__link-in-new-tab {
        width: 106px;
        height: 67px;
        position: absolute;
        top: 0;
        z-index: 101;
        opacity: 0;
      }

      img {
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
        width: 106px;
        height: 70px;
        object-fit: cover;
      }

      .file-thumbnail {
        display: none;
      }

      .button-block {
        display: block;
        visibility: hidden;
        position: absolute;
        top: 0;
        left: inherit;
        right: -10px;
        z-index: 150;

        .action-buttons {
          background: transparent;

          .fa {
            color: #fff;
          }
        }

        .button-block__list {
          left: 23px;
          min-width: 80px;
          max-width: 100px;

          .button {
            display: block;
            max-width: 80px;
            min-width: 80px;
            border-radius: 0;
            font-size: 14px;
            font-weight: 400;
            height: 26px;
            line-height: 26px;
            padding: 0 7px;
            text-transform: lowercase;
          }
        }

        &.active {
          visibility: visible;

          & + .file-preview__hover {
            opacity: 1;
          }
        }
      }

      &:hover {
        .button-block {
          visibility: visible;
        }

        .file-preview__hover {
          opacity: 1;
        }
      }
    }
  }
}
