@import 'src/assets/scss/color-palette';

.modal {
  :global {
    .modal-dialog {
      width: 600px;
    }
  }
}

.login {
  margin: 0 0 20px 0;
  color: $bright-gray;

  .label {
    text-transform: uppercase;
  }

  .alert {
    background: $light-gray;
    height: 34px;
    padding: 5px 15px;
    line-height: 24px;
    border-radius: 4px;
  }
}
