.item {
  border: 1px solid #eaeef1;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;

  :global {
    .settlements-list {
      &__header {
        background: #f6f9f8;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 10px 15px;
        cursor: pointer;

        &:hover {
          background: #eaeef1;
        }

        .address {
          line-height: 20px;
        }
      }

      &__content {
        display: none;

        &_descr {
          background: #f6f9f8;
          padding: 10px 15px;
        }
      }

      &.open {
        .settlements-list__header {
          background: #eaeef1;
        }

        .settlements-list__content {
          display: block;
        }
      }

      &__select-all {
        padding: 10px 0 10px 15px;

        .iw_cb_shell label {
          width: 100%;
          line-height: 21px;
          margin: 3px 0 0 10px;
          white-space: nowrap;
          overflow: hidden;
          text-transform: none;
          font-size: 16px;
        }
      }
    }
  }
}
