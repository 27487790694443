@import 'src/assets/scss/color-palette';

.grid {
  background: $white;
  color: $regent-gray;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 300;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  position: relative;
  height: calc(110px - 41px);
  min-width: 106px;
  overflow: hidden;

  :global {
    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      background: $light-gray;
      width: 20px;
      height: 20px;
      border-radius: 0;
      border-bottom-left-radius: 2px;
      line-height: 0;
      padding: 0 2px;
      margin: 0;

      &:hover {
        background: $light-gray;
      }

      .fa {
        font-size: 14px;
      }
    }

    img {
      border-top-right-radius: 2px;
      border-top-left-radius: 2px;
      width: 106px;
      height: 70px;
      object-fit: cover;
    }
  }
}
