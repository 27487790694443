@import 'assets/scss/color-palette';

.dispatcher {
  text-align: right;

  :global {
    .message-from {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 14px;

      .sep {
        margin: 0 5px;
      }
    }

    .message-text {
      padding: 5px 20px 5px 10px;
      display: inline-block;
      min-width: 150px;
      background: $cerulean;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      color: $white;
      line-height: 1.2;
      text-align: left;
      white-space: pre-line;
      word-break: break-word;
    }

    .message-date {
      margin-top: 5px;
      color: $regent-gray;
      font-size: 14px;
      line-height: 1.2;
    }

    .message-link {
      color: $white;
      line-height: 1.2;
      text-decoration: underline;
      transition: 0.3s;

      &:hover {
        opacity: 0.6;
      }
    }

    .message-attach {
      margin-top: 5px;
      display: flex;
      justify-content: flex-end;

      .file-item {
        border: 2px solid $cerulean;
        border-radius: 4px;
        position: relative;
        padding: 0;
        height: 110px;
        width: 110px;
        align-items: flex-start;

        .file-preview .button-block .button-block__list {
          right: 18px;
          left: inherit;
        }
      }

      .file-name-tooltip {
        background: $cerulean;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        color: $bright-gray;
        display: block;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        line-height: 1.3;
        position: absolute;
        padding: 2px 5px;
        margin-right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        overflow: visible;
        cursor: pointer;
      }

      .file-name {
        color: $white;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .file-size {
        display: block;
        color: $white;
      }

      .file-buttons {
        display: none;
      }
    }
  }
}

.driver {
  text-align: left;

  :global {
    .message-text {
      padding: 5px 20px 5px 10px;
      display: inline-block;
      min-width: 150px;
      background-color: $white;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      border-top-right-radius: 10px;
      color: $bright-gray;
      line-height: 18.75px;
      white-space: pre-wrap;
      word-break: break-word;
    }

    .message-date {
      margin-top: 5px;
      color: $regent-gray;
      font-size: 14px;
      line-height: 1.2;
    }

    .message-link {
      color: $cerulean;
      line-height: 1.2;
      text-decoration: underline;
      transition: 0.3s;

      &:hover {
        opacity: 0.6;
      }
    }

    .message-status {
      margin-top: 5px;
      display: inline-block;
      color: $white;
      background: $green-haze;
      padding: 5px;
      border-radius: 4px;
      font-size: 14px;
      line-height: 1;
    }

    .message-attach {
      margin-top: 5px;
      display: flex;
      justify-content: flex-start;

      .file-item {
        border: 2px solid $white;
        border-radius: 4px;
        position: relative;
        padding: 0;
        height: 110px;
        width: 110px;
        align-items: flex-start;

        .file-preview .button-block .button-block__list {
          right: 18px;
          left: inherit;
        }
      }

      .file-name-tooltip {
        background: $white;
        color: $bright-gray;
        display: block;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        line-height: 1.3;
        position: absolute;
        padding: 2px 5px;
        margin-right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        overflow: visible;
        cursor: pointer;
      }

      .file-name {
        width: 100%;
        color: $tuna;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .file-size {
        display: block;
        color: $regent-gray;
      }

      .file-buttons {
        display: none;
      }
    }
  }
}
