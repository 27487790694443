@import 'src/assets/scss/color-palette';

:global {
  .has-error {
    [class*="input_wrap"] {
      button {
        background: rgba(255, 82, 83, 0.2) !important;
      }
    }
  }
}

.input_item {
  .input_wrap {
    position: relative;

    button {
      position: absolute;
      top: 1px;
      right: 1px;
      border-radius: 4px;
      height: 32px;
      background: $snow-drift;
      color: $regent-gray;
    }

    button:hover {
      cursor: pointer;
    }
  }

  :global {
    .form-group {
      position: relative;
    }

    .invalid-feedback.error {
      right: 40px;
    }

    .input-group .form-control {
      border-radius: 4px;
    }
  }
}
