.spinner {
  width: 100%;
  color: #353a45;
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon {
    line-height: 1.3;
    font-size: 20px;
    text-align: center;
    color: grey;
  }
}
