@import 'src/assets/scss/color-palette';

.wrap {
  padding: 0 10px;
  margin-bottom: 0;
  display: inline-block;
  min-width: 75px;
  height: 20px;
  background-color: $tower-gray;
  border: 1px solid $tower-gray;
  border-radius: 4px;
  color: $white;
  font-size: 14px;
  line-height: 19px;
  text-transform: lowercase;
  text-align: center;

  &.green {
    background-color: $shamrock;
    border: 1px solid $shamrock;
  }

  &.blue {
    background-color: $pattens-blue;
    border: 1px solid $pattens-blue;
    color: $bright-gray;
  }

  &.yellow {
    background-color: $yellow-orange;
    border: 1px solid $yellow-orange;
  }

  &.gray {
    background-color: $porcelain;
    border: 1px solid $porcelain;
    color: $tuna;
  }

  &.light-green {
    background-color: $loafer;
    border: 1px solid $loafer;
    color: $bright-gray;
  }

  &.red {
    border: 1px solid $fair-pink;
    background-color: $fair-pink;
    color: $sunset-orange;
    font-size: 12px;
  }

  &.large {
    min-width: 120px;
  }
}

.border {
  background-color: transparent;
  color: $tuna;

  &.green {
    background-color: $white;
    border: 1px solid $shamrock;
  }

  &.yellow {
    background-color: transparent;
    border: 1px solid $yellow-orange;
  }

  &.blue {
    background-color: transparent;
    border: 1px solid $light-blue;
    color: $bright-gray;
  }

  &.gray {
    background-color: $white;
    border: 1px solid $tower-gray;
    color: $tuna;
  }

  &.red {
    background-color: transparent;
    border: 1px solid $fair-pink;
    color: $sunset-orange;
  }
}
