@import 'src/assets/scss/color-palette';

.group {
  opacity: 1;

  :global {
    .btn-group__filter {
      margin: 20px 20px 0;
      justify-content: flex-start;

      .btn,
      .btn.active {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 65px;
        height: 25px;
        color: $tuna;
        font-family: Roboto, Arial, sans-serif;
        font-size: 14px;
        background-color: $porcelain;
        border: none;

        &:hover {
          background-color: $alto;
        }
      }

      .btn:not(:last-child) {
        margin-right: 3px;
      }

      .btn:not(.disabled):hover {
        color: $tuna;
      }

      .btn:not(.disabled):active {
        color: $tuna;
        background-color: $porcelain;
      }

      .btn-default:not(:disabled):not(.disabled).active {
        background-color: $spindle;
        color: $tuna;
      }
    }
  }
}
