@import 'assets/scss/color-palette';

.container {
  flex: 1;
  display: flex;
  gap: 10px;

  .inputContainer {
    flex: 1;
  }

  .label {
    font-size: 14px;
  }

  .input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px 0 15px;
    gap: 5px;
    height: 34px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid $tower-gray;
    background-color: $snow-drift;
  }

  .timeSelectWrapper {
    display: flex;
    gap: 10px;
  }

  .timeSelect {
    display: flex;
    align-items: center;
  }
}
