.block {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 220px;
  background-color: #f7f9f9;

  :global {
    .image-block-inner {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-flow: row wrap;

      .img-circle {
        width: 140px;
        height: 140px;
        object-fit: cover;
      }
    }

    .button-img-upload {
      background-color: #fff;
      color: #353a45;
      border-radius: 3px;
      text-align: center;
      min-width: 259px;
      flex-grow: 0.5;
      margin: 5px;
    }

    .button-file-input {
      position: relative;
      display: block;
      margin: 5px;
      min-width: 200px;
    }

    .button-file-input input {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      text-align: right;
      filter: alpha(opacity=0);
      opacity: 0;
      outline: none;
      background: white;
      cursor: inherit;
      display: block;
    }

    .button-img-upload:hover {
      background-color: #f2f2f2;
    }

    .button-img-upload:active {
      background-color: #c1c1c1;
    }
  }
}
