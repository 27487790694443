@import 'src/assets/scss/color-palette';

.batch_info_header_container {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid $light-gray;

  >*:nth-child(2) {
    width: 25%;
  }
}

.button_filter {
  user-select: none;
}
