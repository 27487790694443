.item {
  &.noBorder {
    border: none;
    padding: 0;
  }

  :global {
    .timezone-fields {
      display: flex;
      width: 90%;
      align-items: flex-start;

      > div {
        margin-right: 10px;
        margin-bottom: 0;
        min-height: 74px;

        &:nth-child(1) {
          min-width: 130px;
          max-width: 180px;
        }

        &:nth-child(2) {
          min-width: 130px;
        }

        &.react-datepicker-wrapper {
          min-width: 130px;
          max-width: 180px;
        }

        &.react-datepicker__tab-loop {
          min-width: auto;
          margin-right: 0;
          margin-bottom: 0;
          min-height: 0;
        }
      }
    }
  }
}
