@import 'assets/scss/color-palette';

.item {
  border: 2px solid $light-porcelain;
  border-radius: 4px;
  position: relative;
  margin: 5px;
  padding: 0;
  height: 110px;
  width: 110px;
  align-items: flex-start;

  :global {
    .file-name-tooltip {
      background: $light-gray;
      color: $bright-gray;
      display: block;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      line-height: 1.3;
      position: absolute;
      padding: 2px 5px;
      margin-right: 0;
      bottom: 0;
      left: -1px;
      width: 108px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      visibility: visible;
      cursor: pointer;
    }

    .file-name {
      width: 98px;
      color: $tuna;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .file-size {
      display: block;
      color: $regent-gray;
    }
  }

  &.invalid {
    border: 2px solid $sunset-orange;

    :global {
      .file-name-tooltip {
        background: $your-pink;
      }
    }
  }
}
