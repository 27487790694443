.checkbox {
  :global {
    .iw_cb_shell {
      margin: 0;

      label {
        margin-left: 8px;
      }
    }

    .iw_cb_shell.iw_cb_shell_checked {
      span.cb_square {
        &::before {
          color: white;
          background-color: #039be5;
        }
      }
    }
  }
}