@import 'assets/scss/color-palette';

.wrap {
  padding: 10px;
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  color: $nevada;
  line-height: 1;
  cursor: pointer;
  transition: 0.6s;

  &:hover {
    color: $tuna;
  }

  &.disable {
    cursor: not-allowed;

    i {
      color: $custom-blue;
    }
  }
}
