@import 'assets/scss/color-palette';

.container {
  line-height: 1;

  .title {
    font-size: 16px;
    color: $regent-gray;
    margin-bottom: 10px;
  }

  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .location_info {
      display: flex;
      align-items: center;

      button {
        padding: 0;
        margin: 0;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .location {
        font-size: 16px;
        margin-left: 10px;
        color: $bright-gray;
      }

      .clipboard {
        margin-left: 10px;
      }
    }

    .date_info {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .date,
      .time_overing {
        font-size: 16px;
        color: $tuna;
      }

      .time_overing {
        span {
          display: block;
          padding-left: 5px;
          margin-left: 5px;
          border-left: 2px solid $regent-gray;
        }
      }
    }
  }
}
