@import 'assets/scss/color-palette';

.notification {
  margin-bottom: 2px;
  padding-top: 2px;
  padding-left: 5px;
  line-height: 14px;
  font-weight: bold;
  border-left: 3px solid $orange;
}

.empty {
  color: $regent-gray;
  font-size: 14px;
}

.message {
  margin-top: 3px;
  color: $bright-gray;
  font-size: 14px;
  line-height: 16px;

  i {
    color: $regent-gray;
  }
}

.date {
  color: $regent-gray;
  font-size: 14px;
  line-height: 17px;
}
