.sep {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 7px;

  .dot {
    width: 1px;
    height: 1px;
    margin-bottom: 1px;
    background: #2f333e;
  }
}
