@import 'color-palette';

.padding_h {
  padding-left: 20px;
  padding-right: 20px;
}

.padding_v {
  padding-top: 15px;
  padding-bottom: 15px;
}

/* STEPS */

.steps {
  position: relative;
  padding: 2px 30px 2px 20px;

  &::before {
    content: "\f105";
    box-sizing: border-box;
    font: normal normal normal 14px/1 FontAwesome, sans-serif;
    position: absolute;
    color: $regent-gray;
    right: 0;
    top: calc(50% - 0.5rem);
    line-height: 1.1rem;
  }

  &:first-of-type {
    padding-left: 0;
  }

  &:last-of-type {
    padding-right: 0;

    &::before {
      content: "";
    }
  }

  .infowin_step_link {
    display: inline-block;
    color: $tuna;
    cursor: default;

    &.infowin_step_disabled {
      cursor: pointer;
      color: $tower-gray;

      &:hover {
        color: darken($tower-gray, 15%);
      }
    }

    &.infowin_step_through {
      cursor: pointer;
      color: $tower-gray;
      text-decoration: line-through;

      &:hover {
        color: darken($tower-gray, 15%);
        text-decoration: line-through;
      }
    }
  }
}
