@import "color-palette";

.static_modal {
  color: #333;
}

.modal_success {
  color: #30b309;
}

.modal_danger {
  color: #c12b2b;
}

.modal_warning {
  color: #cab71c;
}

.modal-note-dialogs {
  width: 700px;
}

.custom-modal {
  .modal-content {
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }

  .receiver-row {
    background: #f7f9f9;
    display: flex;
    font-weight: 400;
    font-size: 15px;
    line-height: 1.2;
    padding: 20px;

    .receiver-cell {
      margin-right: 40px;

      &:last-child {
        margin-right: 0;
        white-space: nowrap;
      }
    }
  }

  .receiver-cell span {
    font-weight: 300;
    margin-left: 10px;
  }
}

.navigate-modal {
  .modal-content {
    border-radius: 10px;
  }

  .modal-header {
    padding: 25px 20px 20px 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: $snow-drift;
  }

  .modal-title {
    font-size: 20px;
    font-weight: 400;
  }

  .warning-icon-block {
    font-size: 24px;
    color: $sunset-orange;
    width: 60px;
    padding-top: 10px;
    text-align: center;
  }
}

.warning-change {
  padding: 15px 15px;
  display: flex;
  align-items: center;
  background-color: $fair-pink;
  border-radius: 5px;

  >.fa {
    font-size: 20px;
    color: $sunset-orange;
    margin-left: 15px;
  }
}

.modal-body {
  .col-11 {
    strong {
      word-break: break-all;
    }
  }
}

.info-modal-no-break {
  .modal-body {
    .col-11 {
      strong {
        word-break: break-word;
      }
    }
  }
}

.standard-form-modal.not-signed-pod-modal {
  .modal-body__custom-scroll {
    padding-bottom: 50px;
  }
}

.modal-backdrop {
  z-index: 1040;
}

.modal {
  z-index: 1050;
}

.modal-overflow-y-auto {
  overflow-y: auto;
}
