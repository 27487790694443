@import 'assets/scss/color-palette';

.wrap {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid $light-gray;
  background-color: $snow-drift;
  border-radius: 4px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: $tuna;
  position: relative;

  .button {
    margin-top: auto;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    transition: color 0.2s;

    &:hover {
      color: $cerulean;
    }
  }

  .title {
    font-size: 16px;
  }

  .badge {
    margin: 0 auto;
    max-width: 140px;
  }
}
