.datepicker-icon {
  color: #84939a;
  float: right;
  font-size: 14px;
  left: -10px;
  position: relative;
  top: -25px;
}

.datepicker-no-form-group {
  position: relative;

  .datepicker-icon {
    position: absolute;
    left: inherit;
    right: 9px;
    top: 10px;
  }
}

.custom-input-with-datepicker {
  .datepicker-icon {
    color: #84939a;
    float: right;
    font-size: 14px;
    left: -4px;
    position: relative;
    top: -19px;
  }
}

.react-datepicker {
  font-size: 1em !important;

  &-with-sidebuttons {
    position: relative;

    .form-control {
      padding-left: 40px;
    }

    .datepicker-icon {
      left: -41px;
    }
  }

  &-decrease,
  &-increase {
    position: absolute;
    top: 1px;
    bottom: 1px;
    z-index: 2;
    width: 32px;
    text-align: center;
    background-color: #e5e8e9;
    cursor: pointer;
    color: #84939a;

    &::before {
      display: block;
      font-family: 'FontAwesome', sans-serif;
      font-size: 18px;
      line-height: 1;
      width: 18px;
      height: 18px;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }

    &:hover {
      &::before {
        color: #039be5;
      }
    }

    &:active {
      background-color: #b0bec6;

      &::before {
        color: #353a45;
      }
    }

    &.disabled {
      pointer-events: none;
      background-color: #e5e8e9;
      color: #84939a;
    }
  }

  &-decrease {
    left: 1px;
    border-radius: 4px 0 0 4px;
    border-right: 1px solid #d0d0d0;

    &::before {
      content: '\f104';
    }
  }

  &-increase {
    right: 1px;
    border-radius: 0 4px 4px 0;
    border-left: 1px solid #d0d0d0;

    &::before {
      content: '\f105';
    }
  }
}

.react-datepicker__header {
  padding-top: 0.8em !important;
}

.react-datepicker__month {
  margin: 0.4em 1em !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  line-height: 1.9em !important;
  margin: 0.166em !important;
  width: 1.9em !important;
}

.react-datepicker__current-month {
  font-size: 1em !important;
}

.react-datepicker__navigation {
  border: 0.45em solid transparent !important;
  line-height: 1.7em !important;
  top: 1em !important;
}

.react-datepicker__navigation--previous {
  border-right-color: #ccc !important;
  left: 1em !important;
}

.react-datepicker__navigation--next {
  border-left-color: #ccc !important;
  right: 1em !important;
}

.react-datepicker__month-container {
  height: 100%;
  max-height: 288px;
}

.datepicker-component {
  overflow: hidden;

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    height: 100%;
    max-height: 246px;
    overflow-x: hidden;
  }

  .react-datepicker__time-container {
    width: 100px;
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 100px;
  }

  .react-datepicker__navigation--previous {
    border-right-color: #226ca5 !important;
    left: 1em !important;
  }

  .react-datepicker__navigation--next {
    border-left-color: #226ca5 !important;
    right: 1em !important;
  }

  .react-datepicker-time__header {
    padding-right: 25px;
  }
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;

  .invalid-feedback.error {
    right: 30px;
  }
}

.react-datepicker-popper[data-placement^='top'],
.react-datepicker-popper[data-placement^='bottom'] {
  z-index: 9999;
}
