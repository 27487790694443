@import 'src/assets/scss/color-palette';

.error {
  padding: 20px;

  i {
    font-size: 24px;
    color: $sunset-orange;
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: $sunset-orange;
    padding: 0;
    margin: 0 0 10px 0;
  }

  p {
    padding: 0;
    margin: 0 0 2px;
  }
}
