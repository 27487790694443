@import 'src/assets/scss/color-palette';

.checkbox {
  vertical-align: middle;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #b0bfc6;
  text-align: center;
  border-radius: 4px;

  &::before {
    content: "";
    font-family: 'FontAwesome', sans-serif;
    line-height: 18px;
    font-size: 14px;
    color: $white;
    display: none;
  }

  &__checked {
    background-color: $cerulean;

    &::before {
      display: block;
      content: "\f00c";
    }
  }
}
