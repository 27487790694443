.modal {
  :global {
    .col-lg-3 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .col-lg-6,
    .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    h3 {
      font-size: 30px;
      font-weight: 300;
      line-height: 1;
      margin-bottom: 10px;
    }

    .bcf {
      &__main-info {
        &.modal-body__custom-scroll {
          padding: 30px 20px 30px;
        }

        &_item {
          padding-top: 20px;

          &:first-child {
            padding-top: 0;
          }

          .iw_cb_shell {
            margin: 10px 0 15px 0;

            span.cb_square {
              margin-right: 10px;
            }
          }

          label {
            font-weight: 400;
          }

          .item-bg {
            background-color: #f7f9f8;
            padding: 30px 30px 20px 30px;
            border-radius: 0.25rem;

            .form-label {
              margin-bottom: 5px;
            }

            .iw_rb_shell.iw_rb_horisontal > div {
              margin-right: 0;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }

      &__note {
        padding: 30px 20px 15px;
        border-bottom: 1px solid #ebeff2;
      }

      &__footer {
        padding: 20px;
        display: flex;
        justify-content: space-between;

        .button {
          padding-left: 40px;
          padding-right: 40px;

          &-green {
            min-width: 200px;
          }
        }
      }
    }

    .city-state-block {
      display: flex;

      &_item {
        margin-right: 20px;
        flex: 1;
      }

      &_item:last-child {
        margin-right: 0;
      }
    }
  }
}
