@import "color-palette";

.standard-form-modal {
  &.modal-dialog {
    width: 1000px;
    max-width: inherit;
  }

  &.modal-md {
    width: 820px;
    max-width: inherit;
  }

  &.modal-sm {
    width: 680px;
    max-width: inherit;
  }

  &.modal-xs {
    width: 540px;
    max-width: inherit;
  }

  &.custom-modal {
    max-width: 600px;
  }

  button {
    &:focus,
    &:active {
      outline: none;
    }
  }

  label {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 14px;
  }

  .form-control {
    &:focus {
      box-shadow: none;
    }
  }

  .modal-header {
    width: 100%;
    padding: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
    border-bottom: 1px solid #ebeff2;

    &__top {
      width: 100%;
      padding: 20px;
      background: #f7f9f9;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .modal-title {
        color: #2f333e;
        font-weight: 400;
        font-size: 20px;

        span {
          font-weight: 500;
        }
      }

      .modal-subtitle {
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: #84939a;
      }

      button {
        font-size: 20px;

        &.close {
          color: #84939a;
          opacity: 1;

          &:hover {
            color: #039be5;
          }
        }
      }
    }
  }

  .modal-body {
    padding: 0;

    &__sep {
      padding: 20px;
      border-bottom: 1px solid #ebeff2;
    }

    &__main {
      padding: 20px;

      &_item {
        margin-top: 15px;

        &_border {
          padding: 10px 20px 20px;
          margin: 0 -20px;
          border-top: 1px solid $light-gray;
          border-bottom: 1px solid $light-gray;
        }

        &:first-child {
          margin-top: 0;
        }

        h2 {
          color: #424242;
          font-size: 30px;
          font-weight: 300;
          margin-bottom: 20px;
        }

        &.add-note-field {
          border-top: 1px solid #eceff1;
          margin-top: 10px;
          padding-top: 20px;
        }

        .input-group-inline {
          display: flex;
          justify-content: space-between;

          .form-control {
            margin-left: 5px;
            margin-right: 5px;

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }

          &.fw70 {
            .form-control {
              &:nth-child(1) {
                width: 70%;
              }

              &:nth-child(2) {
                width: 30%;
              }
            }
          }
        }

        table {
          margin-top: 10px;

          td {
            vertical-align: top;
            width: 60%;

            &:last-child {
              width: 40%;
            }
          }
        }
      }
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
      text-align: left;
      padding: 20px;
      border-top: 1px solid #ebeff2;

      .button {
        min-width: 160px;
        padding: 0 30px;

        &.button-small {
          min-width: 130px;
        }

        &.button-icon {
          min-width: inherit;
          padding: inherit;
        }
      }

      > div {
        &:first-child {
          .button {
            margin: 0 10px;

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    &__custom-scroll {
      margin-top: 0;
      padding: 4px 0 0 0;
      overflow-y: auto;
      max-height: 600px;

      &::-webkit-scrollbar-track {
        border-radius: 3px;
        background-color: #eaeff2;
      }

      &::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: #aebfc5;
      }
    }
  }
}
