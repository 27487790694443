@import "color-palette";

.nav-tabs {
  background: $snow-drift;
  padding-top: 30px;

  & > a {
    background: $snow-drift;
    border: none;
    color: $regent-gray;
    text-decoration: none !important;
    margin: 0 25px;
    padding: 5px 10px;
    outline: none;

    &:hover {
      border: none;
      text-decoration: none;
      background: $snow-drift;
      color: $regent-gray;
      margin: 0 25px;
      padding: 5px 10px;
    }
  }

  & > a.active,
  & > a:hover.active,
  & > a:focus.active {
    background: $snow-drift;
    border: none;
    border-bottom: 3px solid $dodger-blue;
    color: $tuna;
    outline: none;
  }
}
