@import 'src/assets/scss/color-palette';

.modal {
  :global {
    .modal-header {
      background: $snow-drift;
      align-items: center;

      button {
        font-size: 20px;
      }
    }

    .modal-title {
      font-size: 20px;
      font-weight: 400;
      color: $tuna;
    }
  }
}
