@import "src/assets/scss/color-palette";

.item {
  display: flex;
  grid-gap: 10px;
  padding-bottom: 20px;
  cursor: pointer;
  transition: 0.2s color;
  min-width: 200px;

  .label {
    white-space: nowrap;
  }

  span:first-child {
    display: inline-block;
    width: 25px;
    height: 25px;
    box-shadow: 0 0 4px $regent-gray;
  }

  &:hover {
    color: $cerulean;
  }

  .color_picker {
    position: absolute;
    z-index: 100;
    margin-top: 30px;
  }
}
