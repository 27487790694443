.tn-filter-result {
  margin: 15px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  >* {
    margin: 0 5px;
  }

  .alert {
    padding: 1px 5px;
    background: #fff;
    box-shadow: none;
    border: 1px solid #b0bfc6;
    border-radius: 0;
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    &__remove {
      font-size: 14px;
      line-height: 13px;
      padding: 0 5px 0 0;

      &:focus {
        outline: none;
      }
    }

    &:last-child {
      margin-left: auto;
    }

    .text-case {
      text-transform: initial;
    }
  }
}
