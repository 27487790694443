.wrap {
  height: 20px;
  line-height: 19px;
  min-width: 100px;
  text-transform: lowercase;
  border-radius: 4px;
  display: inline-block;
  background-color: #ebeff2;
  color: #798488;
  padding: 0 10px;
  margin-bottom: 0;
  text-align: center;
  font-size: 14px;


  &.yellow {
    background-color: #ffddb3;
    border: 1px solid #ffddb3;
    color: #2f333e;
  }

  &.gray {
    background-color: #ebeff2;
    border: 1px solid #ebeff2;
    color: #798488;
  }

  &.red {
    background-color: #ffbaba;
    border: 1px solid #ffbaba;
    color: #2f333e;
  }
  &.blue {
    background-color: #ecf9ff;
    border: 1px solid #ecf9ff;
    color: #2f333e;
  }
  &.green {
    background-color: #e5f9f0;
    border: 1px solid #e5f9f0;
    color: #2f333e;
  }
}
