@import "src/assets/scss/color-palette";

.wrap {
  margin: 0 20px 10px;
  padding: 10px 20px;
  border: 1px solid $porcelain;
  border-radius: 3px;
  background: $snow-drift;
  line-height: 1.2;

  .title {
    color: $regent-gray;
    margin-bottom: 5px;
  }

  .milesSpeed {
    display: flex;
    align-items: center;
    grid-gap: 10px;

  }
  .speed {
    text-transform: uppercase;

    .more60 {
      display: inline-block;
      padding: 2px 4px;
      border-radius: 0;
      line-height: 19px;
      color: $white;
      background-color: $sunset-orange;
    }

    .less30 {
      display: inline-block;
      padding: 2px 4px;
      border-radius: 0;
      line-height: 19px;
      color: $white;
      background-color: $orange;
    }
  }



}