@import 'assets/scss/color-palette';

.count {
  padding: 0 6px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 23px;
  height: 18px;
  font-weight: 500;
  color: $white;
  font-size: 12px;
  border-radius: 12px;
  background-color: $jungle-green;
}
