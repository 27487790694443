@import 'color-palette';

body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
  //background for #celebrationNewYear theme;
  //background: url('../../celebration/NewYear/imgs/bg.svg') 0 0 repeat $porcelain;
  background-color: $porcelain;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

$header-height: 60px;
$footer-height: 30px;

.ReactModal__Overlay--after-open {
  z-index: 2000 !important;
}

.input-group-inline {
  .form-group {
    margin: 0;
  }
}

.hidden {
  display: none;
}

.main-data {
  color: $cerulean;
}

.text-transform-none {
  text-transform: none;
}

.ellips-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hr-btn {
  width: 200px;
  border-top: 2px solid #f7f9f8;
  margin-bottom: 15px;
  margin-top: 0;
}

.list-with-gap {
  display: flex;
  grid-gap: 5px;
  align-items: center;
}

.list-with-gap-10 {
  display: flex;
  grid-gap: 10px;
  align-items: center;
}

.fa-diamond {
  color: #2bc97c;
}
