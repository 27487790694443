.wrapper {
  position: relative;

  .minutesSelector {
    position: relative;
    display: flex;
    width: 155px;
    justify-content: space-between;
    align-items: center;

    .decrease {
      left: 1px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .increase {
      right: 1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    :global {
      .button {
        padding: 0 12px;
        position: absolute;
        top: 1px;
        height: 32px;

        i {
          background: transparent;
        }
      }

      .form-control {
        padding-left: 40px;
        padding-right: 70px;
        text-align: center;
      }
    }

    input[type="number"] {
      font-size: 16px;
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }

    .unit {
      position: absolute;
      font-weight: normal;
      transform: translateY(-50%);
      right: 45px;
      top: 50%;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
}
