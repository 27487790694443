@import 'src/assets/scss/color-palette';

.wrap {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 14px;
  height: 100%;
  border: 1px solid $light-porcelain;
  background-color: $white;
  border-radius: 4px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  position: relative;
}

.global {
  background-color: $lily-white;
}

.block {
  padding: 10px;

  a {
    font-size: 16px;
  }

  &:nth-child(1) {
    border-bottom: 1px solid $light-porcelain;
  }
}

.button {
  margin-top: auto;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  transition: color 0.2s;

  &:hover {
    color: $cerulean;
  }
}
