@import "color-palette";

.content-wrap {
  display: flex;
}

.page-wrap {
  display: flex;
  flex: 1;
  min-width: 1600px;
  padding-top: 60px;
  padding-left: 20px;
  padding-right: 20px;
  overflow-x: auto;
  overflow-y: hidden;
}

.router-wrap {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer-wrap {
  height: 30px;
  margin-left: 240px;
  padding-left: 20px;
  padding-right: 20px;
}

.leftbar-closed {
  margin-left: 64px;
}
