@import 'src/assets/scss/color-palette';

.row {
  display: table-row;

  &_reset {
    color: grey;
  }

  &__select {
    display: flex;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}
