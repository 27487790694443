@import "src/assets/scss/color-palette";

.container {

  .inputContainer {
    display: flex;
    border: 1px solid $tower-gray;
    border-radius: 4px;
    overflow: hidden;

    input {
      width: 80px;
      padding-left: 15px;
      font-size: 16px;
      border: none;
    }
  }

  .units {
    display: flex;
    align-items: center;
    padding: 0 10px;
    color: $nevada;
    background-color: $light-porcelain;
  }
}
