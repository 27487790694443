.loader {
  display: flex;
  height: 100px;
  justify-content: center;
}

.wrap {
  .custom_scroll {
    &__pay_records {
      max-height: calc(100vh - 500px);
      min-height: 100px;
      width: 100%;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background: #ebeff2;
      }

      &::-webkit-scrollbar-thumb {
        background: #84939a;
      }
    }
  }
}
