.phone {
  &_wrap {
    position: relative;

    :global {
      .form-control {
        padding-left: 65px;
      }

      .flag-dropdown {
        width: 55px;
        background-color: #e5e8e9;

        .selected-flag {
          padding: 0 8px 0 0;
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &_label {
    background-color: #fff;
  }

  &_button {
    width: 55px;
    background-color: #fff;
  }

  &_error {
    :global {
      .react-tel-input .flag-dropdown {
        border-color: #ff5253 !important;
        background-color: #ffeded !important;
      }

      .react-tel-input .form-control {
        border-color: #ff5253 !important;
        background-color: #ffeded !important;
      }
    }
  }
}
