@import 'src/assets/scss/color-palette';

.badge {
  padding-left: 3px;
  padding-right: 3px;
  position: relative;
  background: $light-porcelain;
  min-width: 20px;
  min-height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: $regent-gray;

  > *:first-child {
    text-align: center;
  }

  &.radius {
    border-radius: 3px;
  }
}
