@import 'assets/scss/_color-palette';

.warn_modal {
  *:not(i) {
    font-family: Roboto, Arial, sans-serif;
  }

  :global {
    .modal-header {
      padding: 20px;
    }
  }

  .modal_title {
    font-size: 20px;
  }

  .entry {
    padding: 20px;
    display: flex;
    align-items: flex-start;

    .warn_icon {
      margin-right: 10px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      color: $sunset-orange;

      i {
        font-size: 26px;
      }
    }

    .warn_info {
      .title {
        font-size: 20px;
      }
    }
  }
}
