@import 'src/assets/scss/color-palette';

.iw_cb_shell {
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  grid-gap: 5px;

  &.f-right {
    float: right;
  }

  label {
    cursor: pointer;
    vertical-align: middle;
    font-size: 16px;
    line-height: 1;
    margin: 0;
    padding: 0;
    text-transform: none;
  }

  span.cb_square {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    border: 1px solid #b0bfc6;
    background-color: #f7f9f8;
    text-align: center;
    border-radius: 4px;

    &::before {
      content: "\f00c";
      font-family: 'FontAwesome', sans-serif;
      line-height: 1;
      font-size: 14px;
      color: $tundora;
      display: none;
    }
  }

  &.iw_cb_shell_checked span.cb_square {
    &::before {
      display: block;
    }
  }

  &:hover {
    span.cb_square {
      border-color: #363a45;
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;

    span.cb_square {
      border: 1px solid #eceff1;
      background-color: #eceff1;
      cursor: not-allowed;
    }

    label {
      color: #b0bfc6;
      cursor: not-allowed;
    }
  }
}

.has-error {
  .iw_cb_shell {
    span.cb_square {
      border: 1px solid #ff5253;
      background-color: #ffeded;
    }
  }
}

.checkbox_single_component {
  position: relative;

  &.redesign {
    .iw_cb_shell {
      margin: 0;

      span.cb_square {
        width: 15px;
        height: 15px;
        margin-right: 5px;

        &::before {
          line-height: 14px;
          font-size: 12px;
        }
      }

      label {
        font-size: 14px;
      }
    }
  }

  .overlay-trigger-wrap {
    display: flex;
  }
}
