@import 'src/assets/scss/color-palette';

.badge {
  height: 20px;
  line-height: 19px;
  min-width: 30px;
  text-transform: none;
  border-radius: 2px;
  display: inline-block;
  background: $tower-gray;
  border: 1px solid transparent;
  color: $tuna;
  margin-bottom: 0;
  padding: 0 5px;
  text-align: center;
}

.green {
  background: $loafer;
  border: 1px solid $shamrock;
}

.red {
  background: $your-pink;
}