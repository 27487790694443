@import "../color-palette";

.custom-design {
  &.button {
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    height: 30px;
    line-height: 30px;
    outline: none;
    padding: 0 20px;
    position: relative;
    text-transform: none;
    transition: background 0.13s;
    vertical-align: middle;
    cursor: pointer;

    &-small {
      padding: 0 5px;
      min-width: 50px;
      height: 20px;
      line-height: 18px;

      .fa {
        color: $bright-gray;
        font-size: 10px;
        line-height: 1;
      }
    }

    &-medium {
      min-width: 120px;
    }

    &-large {
      min-width: 130px;
    }

    &-icon {
      padding: 0;
      width: 20px;
      height: 20px;
      line-height: 1;

      .fa {
        color: $bright-gray;
        font-size: 10px;
        line-height: 16px;
      }

      &.close-button {
        opacity: 1;
        padding: 0;
        background: transparent;
        border: none;

        .fa {
          color: #84939a;
          font-size: 14px;
        }

        &:hover {
          .fa {
            color: $cerulean;
          }
        }
      }
    }

    &-blue {
      background-color: $custom-blue;
      color: $white;

      .fa {
        color: $white;
      }

      &:hover {
        background-color: $cerulean;
      }

      &:active,
      &.active {
        background-color: $cerulean;
      }
    }

    &-grey-blue {
      background-color: $porcelain;
      color: $bright-gray;

      &:hover {
        color: $white;
        background-color: $cerulean;

        .fa {
          color: $white;
        }
      }

      &:active,
      &.active {
        background-color: rgba($cerulean, 0.8);
      }
    }

    &-grey-orange {
      background-color: $porcelain;
      color: $bright-gray;

      &:hover {
        color: $white;
        background-color: $yellow-orange;

        .fa {
          color: $white;
        }
      }

      &:active,
      &.active {
        background-color: rgba($yellow-orange, 0.8);
      }
    }

    &-grey-red {
      background-color: $porcelain;
      color: $bright-gray;

      &:hover {
        color: $white;
        background-color: $jungle-orange;

        .fa {
          color: $white;
        }
      }

      &:active,
      &.active {
        background-color: rgba($jungle-orange, 0.8);
      }
    }

    &-grey-green {
      background-color: $porcelain;
      color: $bright-gray;

      &:hover {
        color: $white;
        background-color: $jungle-green;

        .fa {
          color: $white;
        }
      }

      &:active,
      &.active {
        background-color: rgba($jungle-green, 0.8);
      }
    }

    &-grey-taupe {
      background-color: $porcelain;
      color: $bright-gray;

      &:hover {
        color: $bright-gray;
        background-color: $tower-gray;

        .fa {
          color: $bright-gray;
        }
      }

      &:active,
      &.active {
        background-color: rgba($tower-gray, 0.8);
      }
    }

    &-link {
      background: #fff;
      color: #2f333e;
      text-decoration: underline;

      &:hover {
        background: #fff;
        text-decoration: none;
      }
    }

    &.time-picker-reset {
      min-width: 60px;
      height: 30px;
      margin-top: 18px;
      border: 1px solid #e5e8e9;
      border-radius: 5px;
      background: transparent;
      color: #2f333e;
      padding: 0;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &.dropdown {
    background: transparent;
    display: inline-block;
    border-radius: 5px;
    height: 23px;
    width: 20px;
    text-align: center;

    .dropdown-menu {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }

    .btn {
      padding: 0 0 0 3px;
      display: inline-block;
      line-height: 1;
    }

    .fa {
      font-size: 12px;
      margin-right: 0;
      line-height: 16px;
      color: #7a8185;
    }

    &:hover {
      background: #e5e8e9;

      .fa {
        color: #353a45;
      }
    }

    &.active {
      background: #e2eef6;

      .fa {
        color: #088bd6;
      }
    }

    .dropdown-toggle::after {
      border: 0;
    }

    .dropdown-item {
      font-size: 14px;
      color: #23262f;

      &:active {
        color: #fff;
      }
    }
  }

  .fa {
    font-size: 16px;
    margin-right: 5px;
    line-height: 1;
  }

  &.settings {
    background: transparent;

    .fa {
      font-size: 14px;
      color: #7a8185;
    }

    &:hover {
      background: #e5e8e9;

      .fa {
        color: #353a45;
      }
    }

    &.active {
      background: #e2eef6;

      .fa {
        color: #088bd6;
      }
    }
  }

  &[disabled] {
    cursor: default;
    color: #c7ccce;
    background: #eef0f0;

    .fa {
      color: #c7ccce;
    }

    &:hover {
      color: #c7ccce;
      background: #eef0f0;

      .fa {
        color: #c7ccce;
      }
    }
  }
}
