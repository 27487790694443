.modalContainer {
  .animate {
    animation-name: fadeInDown;
    animation-duration: 0.3s;
  }

  :global {
    .modal {
      width: 100vw;
    }
  }
}

.component {
  background: red;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    top: -100px;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}
