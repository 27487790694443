@import 'assets/scss/color-palette';

.wrap {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  border: 1px solid $white;

  img {
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: inherit;
    height: inherit;
    background-color: $loblolly;

    i {
      width: 21px;
      height: 30px;
      color: $regent-gray;
      font-size: 26px;
    }
  }
}
