.buttonBlock {
  position: relative;
  cursor: pointer;
}

.buttonList {
  position: absolute;
  top: 25px;
  left: inherit;
  right: 0;
  min-width: 185px;
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba(#000, 0.1);
  border-radius: 2px;
  border: 1px solid #ebeff2;
  z-index: 150;
  visibility: hidden;
  transition: all 0.2s;

  button {
    white-space: nowrap;
    text-align: left;
    background-color: #f7f9f8;

    &:hover {
      background-color: #d4d7d9;
    }
  }
}

.buttonListActice {
  visibility: visible;
}