@import 'src/assets/scss/color-palette';

.wrap {
  background: $lily-white;
  padding: 10px 20px;
  margin: 0 0 20px;
  display: flex;
  justify-content: space-between;
}

.title {
  color: $regent-gray;
}

.eventName {
  display: flex;
  grid-gap: 10px;
  align-items: center;
}

.send {
  color: $green-haze;
}

.noSend {
  color: $jungle-orange;
}