@import "src/assets/scss/color-palette";

.wrapper {
  display: flex;
  justify-content: space-between;
}

.loadIcon {
  margin-top: 26px;
}

.arrowDown {
  color: $cerulean;
}

.arrowUp {
  color: $jungle-green;
}

