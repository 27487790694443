.invoice {
  :global {
    .modal-body {
      &__billing {
        padding: 20px;
        border-bottom: 1px solid #ebeff2;

        .iw_rb_shell.iw_rb_horisontal,
        .iw_rb_shell.iw_rb_horisontal > div {
          display: block;
          margin: 0;
        }

        .form-group {
          margin: 0;
        }
      }

      &__files {
        padding: 20px;

        .files-group {
          margin-top: 5px;
          overflow: hidden;
          border-radius: 5px;
        }
      }
    }
  }
}
