@import 'assets/scss/color-palette';

.notification {
  padding: 5px 10px;
  margin-bottom: 10px;
  background-color: $white;
  border-left: 3px solid $orange;
}

.notification__desc {
  white-space: pre-wrap;
  word-break: break-word;
}
