@import 'assets/scss/color-palette';

.warning {
  padding: 15px;
  display: flex;
  background-color: $seashell-peach;
  border-radius: 5px;

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 18px;
    color: $crusta;
  }

  .title {
    color: $orange;
    font-weight: 700;
  }
}
