@import 'src/assets/scss/color-palette';

.dropdown-radio-component {
  font-size: 16px;
  color: #2f333e;
  font-weight: normal;
  text-transform: none;

  .radio-select {
    position: relative;

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #b0bfc6;
      background-color: #f7f9f8;
      min-height: 34px;
      height: 34px;
      min-width: 135px;
      padding: 6px 12px;
      border-radius: 4px;
      cursor: pointer;
      white-space: nowrap;

      .fa {
        margin-left: 5px;
      }

      &.not-allowed {
        cursor: not-allowed;
      }
    }

    &__list {
      display: none;
    }

    &.checked {
      background-color: #eceff1;

      .radio-select__item {
        background-color: #eceff1;
      }
    }

    &.open {
      .radio-select__list {
        display: block;
        position: absolute;
        top: 40px;
        right: 0;
        box-shadow: 0 0 10px 3px rgba(38, 50, 56, 0.05);
        background: #fff;
        border-radius: 4px;
        z-index: 10;
        overflow: hidden;
        min-width: 100%;

        ul {
          li {
            padding: 5px 15px;
            margin-bottom: 4px;
            cursor: pointer;
            white-space: nowrap;
            text-align: left;

            &:hover,
            &.checked {
              background-color: #eceff1;
            }

            &.none-checked {
              color: #b0bfc6;
              border-bottom: 1px solid #eceff1;
            }
          }
        }
      }
    }
  }

  &.disabled {
    .radio-select__item {
      background-color: #ebeff2;
      border: 1px solid transparent;
      box-shadow: none;
      color: #353a45;
      cursor: not-allowed;
    }
  }
}
