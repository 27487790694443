@import 'src/assets/scss/color-palette';

.google_places_autocomplete {
  position: relative;

  .result_box {
    height: auto;
    overflow: hidden;
    background-color: $snow-drift;
    padding: 10px;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 999;
    border-radius: 4px;
  }

  .warning {
    background-color: $seashell-peach;
    box-shadow: 0px 2px 10px 0px $porcelain;

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      color: $crusta;
    }
  }

  .result_item {
    margin-top: 10px;
    cursor: pointer;
    border-bottom: 1px solid rgba(176, 190, 198, 0.4);

    &:last-child {
      border-bottom: none;
    }

    &.active {
      background-color: $tower-gray;
    }
  }
}
