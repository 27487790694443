@import 'src/assets/scss/color-palette';

.wrap {
  display: flex;
  grid-gap: 10px;
  margin-bottom: 15px;
}

.switch {
  width: 34px;
  height: 20px;
  border-radius: 10px;
  background: $tower-gray;
  position: relative;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    background: $regent-gray;
  }

  &::before {
    display: block;
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 8px;
    position: absolute;
    left: 2px;
    top: 2px;
    background: $white;
    z-index: 5;
  }

  &__on {
    background: $cerulean;

    &::before {
      left: inherit;
      right: 2px;
    }

    &:hover {
      background: $dodger-blue;
    }
  }

  &__disabled {
    pointer-events: none;
    cursor: not-allowed;
    background: $tower-gray;
  }
}

.label {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  &__disabled {
    color: $regent-gray;
  }
}
