.form {
  :global {
    .content {
      padding: 20px;
    }

    .footer {
      padding: 20px;
      border-top: 1px solid #ebeff2;
      display: flex;
      justify-content: space-between;
    }

    .measurement-buttons {
      padding-top: 28px;
      display: flex;
      justify-content: space-between;
    }
  }
}
