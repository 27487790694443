.no_pod_badge {
  display: inline-block;
  background: #ffeded;
  color: #ff5253;
  font-weight: 700;
  padding: 0 4px;
  font-size: 14px;
  line-height: 21px;
  min-width: 39px;
  height: 20px;
  text-transform: uppercase;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}
