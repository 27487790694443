.modal {
  max-width: 1080px;
  margin: 30px auto;

  :global {
    .table {
      margin-bottom: 0;

      tbody {
        tr {
          td {
            vertical-align: top;

            &:nth-child(1) {
              min-width: 45px;
            }

            &:nth-child(2) {
              min-width: 95px;
            }

            &:nth-child(3) {
              min-width: 160px;
            }

            &:nth-child(4) {
              max-width: 250px;
            }

            &:nth-child(5) {
              min-width: 240px;
            }
          }
        }
      }
    }

    .modal-header,
    .modal-body__bottom {
      border: 0;
    }
  }
}
