@import 'assets/scss/color-palette';

.slider {
  padding: 0 10px;
  position: relative;

  .left-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .right-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  :global {
    .badge {
      width: 20px;
      height: 20px;
      background-color: $onahau;
      padding: 2px 3px;
      font-weight: 400;
      border-radius: 3px;
      font-size: 14px;
      line-height: 1;
      position: absolute;
      top: -15px;
      left: 0;
    }
  }
}
