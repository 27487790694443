.tripPickUp {
  border-bottom: 2px solid #039be6;

    h6 {
      &::after {
        color: #039be6;
        font-family: FontAwesome, sans-serif;
        font-size: 20px;
        content: "\f149";
        margin-left: 10px;
      }
    }
}


.tripDelivery {
  border-bottom: 2px solid #27af6d;

    h6 {
      &::after {
        color: #27af6d;
        font-family: FontAwesome, sans-serif;
        font-size: 20px;
        content: "\f148";
        margin-left: 10px;
      }
    }
}

.stopInfo {
  display: none;
}

.stopInfoToggle {
  display: flex;
}

