.item {
  background-color: rgba(63, 196, 255, 0.2);
  border-radius: 2px;
  height: 18px;
  line-height: 1.2;
  padding: 1px 3px;
  margin: 3px 5px 5px 0;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
}
