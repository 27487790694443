.total {
  background: #f7f9f8;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  border-bottom: 1px solid #ebeff2;

  &__miles {
    width: 340px;
    display: flex;
    justify-content: flex-end;

    &_titles{

    }

    &_data {
      margin-left: 20px;
      padding-left: 10px;
      width: 80px;
    }
  }

  &__payment {
    width: 285px;
    display: flex;
    justify-content: flex-end;
  }
}

.footer {
  background-color: #f7f9f8;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__total {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 700;

    &_data {
      margin-left: 20px;
      min-width: 120px;
      display: flex;
      justify-content: space-between;
    }
  }
}
