@import 'assets/scss/color-palette';

.tag {
    margin: 2px;
    padding: 2px 20px 2px 5px;
    display: flex;
    align-items: center;
    border: 1px solid $regent-gray;
    border-radius: 2px;
    background-color: $white;
    position: relative;
}

.tag__label {
    font-size: 14px;
    line-height: 16px;
}

.tag__remove {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: $regent-gray;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 2px;
    transition: color 150ms ease 0s;

    &:hover {
        color: $nevada;
    }
}

.tag__count {
    padding: 0 5px;
    display: flex;
    align-items: center;
    border: 1px solid $regent-gray;
    border-radius: 2px;
    background-color: $white;
    position: absolute;
    top: 50%;
    right: 70px;
    transform: translateY(-50%);
}

.clear {
    color: $alto;
    display: flex;
    padding: 8px;
    transition: color 150ms ease 0s;
    box-sizing: border-box;

    &:hover {
        color: $regent-gray;
    }
}

.select {
    position: relative;

    [class$='-indicatorContainer'] {
        padding: 4px;
    }

    [class$='-control'] {
        background: $snow-drift;
        border-color: $tower-gray;
        min-height: 34px;
        min-width: 130px;
        line-height: 1;
    }

    [class$='-control--is-focused'],
    [class$='-control--menu-is-open'] {
        min-width: 130px;
        min-height: 34px;
    }

    [class$='-menu'] {
        z-index: 1000;
        font-size: 14px;

        [class*='option'],
        [class*='option--is-focused'],
        [class*='option--is-selected'] {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:not(:last-child) {
            border-bottom: 1px solid $light-gray;
        }
        }
    }
}

.error {
    position: relative;

    [class$='-control'] {
        background-color: lighten($sunset-orange, 30%);
        border-color: $sunset-orange;
    }
}
