@import 'assets/scss/color-palette';

.wrap {
  :global {
    .drop-zone-title {
      margin-top: 29px;
      text-align: center;

      .fa {
        color: $regent-gray;
        margin-right: 5px;
      }
    }

    .drop-zone-container {
      width: 100%;
      height: 85px;
      border: 2px dashed $tower-gray;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.2s;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      &:hover {
        border-color: $cerulean;

        .drop-zone-title {
          .fa {
            color: $cerulean;
          }
        }
      }
    }
  }
}
