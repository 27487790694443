.modal {
  :global {
    .modal-dialog {
      width: 1000px;
      color: #2f333e;
    }

    .modal-header {
      background: #f7f9f9;

      .modal-title {
        font-size: 20px;
        color: #2f333e;
      }
    }

    .modal-body {
      padding: 0;

      .custom-scroll {
        padding: 1rem;
      }
    }

    .general-data {
      &__count-duplicate-companies {
        margin-bottom: 10px;
      }

      &__company-data {
        margin-bottom: 20px;
      }

      &__info-merge {
        margin-bottom: 10px;

        .warning-info {
          display: inline-flex;
          align-items: center;
          padding: 5px 10px;
          background: #fff6ed;
          border-radius: 4px;
          margin-top: 5px;

          .fa {
            color: #ffab40;
            font-size: 14px;
            margin-right: 10px;
          }
        }
      }
    }

    .table-duplicates {
      border: 1px solid #ebeff2;

      tr {
        td {
          &:last-child {
            width: 161px;
          }
        }
      }
    }

    .coincidence {
      padding: 0 3px;
      background: #e5f9f0;
    }
  }
}
