.wrapper {
  width: 106px;
  text-align: center;

  :global {
    .table-pic__block {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      width: 20px;
    }
  }
}
