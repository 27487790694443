@import 'src/assets/scss/color-palette';

.group {
  opacity: 1;

  :global {
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 65px;
      height: 25px;
      color: $tuna;
      font-family: Roboto, Arial, sans-serif;
      font-size: 14px;
      background-color: $porcelain;
      border: none;

      &.active.disabled {
        background-color: $alto;
      }
    }

    .btn-outline-dark:not(:disabled):not(.disabled).active {
      background-color: #bdd7ed;
      color: $tuna;
    }

    .btn:not(:last-child) {
      margin-right: 3px;
    }
  }
}
