@import 'color-palette';

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

// height
.h-fit {
  height: fit-content;
}

// max-width
.maxw50 {
  max-width: 50px;
}

.maxw100 {
  max-width: 100px;
}

.maxw150 {
  max-width: 150px;
}

.maxw200 {
  max-width: 200px;
}

.maxw250 {
  max-width: 250px;
}

.maxw300 {
  max-width: 300px;
}

.maxw325 {
  max-width: 325px;
}

// width
.w50 {
  width: 50px;
}

.w100 {
  width: 100px;
}

.w150 {
  width: 150px;
}

.w200 {
  width: 200px;
}

.w250 {
  width: 250px;
}

.w300 {
  width: 300px;
}

.w325 {
  width: 325px;
}

.w350 {
  width: 350px;
}

.w375 {
  width: 375px;
}

.w400 {
  width: 400px;
}

.w100p {
  width: 100%;
}

// margin
.m5 {
  margin: 5px;
}

.m10 {
  margin: 10px;
}

.m15 {
  margin: 15px;
}

.m20 {
  margin: 20px;
}

.m25 {
  margin: 25px;
}

// margin-top
.mt0 {
  margin-top: 0;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

// margin-right
.mr0 {
  margin-right: 0;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr25 {
  margin-right: 25px;
}

// margin-bottom
.mb0 {
  margin-bottom: 0;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb100 {
  margin-bottom: 100px;
}

// margin-left
.ml0 {
  margin-left: 0;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.ml25 {
  margin-left: 25px;
}

// padding
.p5 {
  padding: 5px;
}

.p10 {
  padding: 10px;
}

.p15 {
  padding: 15px;
}

.p20 {
  padding: 20px;
}

.p25 {
  padding: 25px;
}

// padding-top
.pt0 {
  padding-top: 0;
}

.pt5 {
  padding-top: 5px;
}

.pt10 {
  padding-top: 10px;
}

.pt15 {
  padding-top: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pt25 {
  padding-top: 25px;
}

// padding-right
.pr0 {
  padding-right: 0;
}

.pr5 {
  padding-right: 5px;
}

.pr10 {
  padding-right: 10px;
}

.pr15 {
  padding-right: 15px;
}

.pr20 {
  padding-right: 20px;
}

.pr25 {
  padding-right: 25px;
}

// padding-bottom
.pb0 {
  padding-bottom: 0;
}

.pb5 {
  padding-bottom: 5px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb25 {
  padding-bottom: 25px;
}

// padding-left
.pl0 {
  padding-left: 0;
}

.pl5 {
  padding-left: 5px;
}

.pl10 {
  padding-left: 10px;
}

.pl15 {
  padding-left: 15px;
}

.pl20 {
  padding-left: 20px;
}

.pl25 {
  padding-left: 25px;
}

.gap5 {
  gap: 5px;
}

.gap10 {
  gap: 10px;
}

.gap20 {
  gap: 20px;
}

// custom horizontal rule that is used to separate content or like a border
.bottom-hr {
  border-bottom: 1px solid $mercury;
}

.top-hr {
  border-top: 1px solid $mercury;
}

.ws-nowrap {
  white-space: nowrap;
}

.ws-normal {
  white-space: normal;
}

.font-thin {
  font-weight: 100;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-shamrock {
  background: $shamrock;
}

.bg-sunset-orange {
  background: $sunset-orange;
}

.bg-yellow-orange {
  background: $yellow-orange;
}

.border-sunset-orange {
  border: 1px solid $sunset-orange;
  color: $tuna !important;
}

.border-shamrock {
  border: 1px solid $shamrock;
  color: $tuna !important;
}

.extension {
  margin: 0 0 0 -5px;
  padding: 0 10px 0 0;
}

.img-circle {
  border-radius: 50%;
}

// Border radius
.br2 {
  border-radius: 2px;
}
