@import "src/assets/scss/color-palette";

.content {
  padding: 10px 20px 20px;
}

.message {
  position: relative;

  .scroll {
    width: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: $light-gray;
    }

    &::-webkit-scrollbar-thumb {
      background: $regent-gray;
    }
  }

  >button {
    position: absolute;
    right: 5px;
    top: 30px;
  }
}

.to {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-top: 1px solid $porcelain;
  margin: 5px 0 0 0;
  padding: 10px 0 0 0;

  .emailsSelect {
    max-width: calc(100% - 42px);
  }

  >*:nth-child(1) {
    width: 100%;
  }

  >button {
    margin-left: 5px;
    margin-top: 21px;
  }
}

.from {
    margin-bottom: 15px;
}
