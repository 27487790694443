.form {
  :global {
    .custom-scroll {
      max-height: calc(100vh - 400px);
    }

    .modal-body {
      &__main {
        padding: 10px 20px;

        .iw_cb_shell {
          margin: 0;

          span.cb_square {
            margin-right: 10px;
          }

          label {
            text-transform: none;
            font-size: 16px;
          }
        }

        .check-send-email {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .button-icon {
            .fa {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
