@import "src/assets/scss/color-palette";

.info {
  display: flex;
  padding: 10px 20px;
  margin: 20px 20px 0 20px;
  border-radius: 5px;
  background: $seashell-peach;
}

.icon {
  color: $yellow-orange;
  margin-right: 10px;
}

.content {
  color: $tuna;
}
