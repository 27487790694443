.dialog {
  width: 1000px;
}

.body {
  padding: 0;
}

.wrapper {
  padding: 0 1rem;
}
