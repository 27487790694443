.input {
  border: 2px solid #ebeff2;
  border-radius: 4px;
  margin-bottom: 15px;

  :global {
    div:first-child {
      .file-item {
        border: 0;
      }
    }

    .button-block {
      display: flex;
      align-items: center;
      padding: 10px;

      .form-control,
      .Select {
        width: 180px;
        margin-right: 10px;
      }

      .button-file-input {
        position: relative;
        display: block;
        margin: 5px;

        input {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          text-align: right;
          filter: alpha(opacity=0);
          opacity: 0;
          outline: none;
          background: white;
          cursor: inherit;
          display: block;
        }
      }
    }
  }
}
