.item {
  padding: 10px 20px;
  background-color: #ebeff2;
  display: flex;
  justify-content: space-between;
  align-items: center;

  :global {
    .open-period {
      cursor: pointer;

      .fa {
        color: #84939a;
        margin-left: 10px;
      }
    }
  }
}
