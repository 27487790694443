@import 'src/assets/scss/color-palette';

.container {
  padding: 10px 25px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $fair-pink;
  color: $sunset-orange;

  .icon {
    font-size: 16px;
    margin-right: 20px;
  }

  .text {
    font-size: 14px;
    line-height: 1;
  }
}
