.items {
  :global {
    .view-files {
      border-radius: 0 0 4px 4px;
      border: 1px solid #eceff1;
      max-height: 552px;

      &_scroll {
        overflow-y: auto;
      }
    }

    .view-files a.button-sm {
      color: #84939a;
    }

    .view-files a.button-sm:hover {
      color: #039be5;
    }

    .empty-list {
      padding: 30px 10px;
      color: #353a45;
      font-size: 18px;
      line-height: 44px;
      text-align: center;
      font-weight: 500;
    }

    .hide_field {
      display: none;
    }

    .files-outer .file-search {
      border: none;
      position: absolute;
      top: 6px;
      right: 60px;
      width: 200px;
      z-index: 10;
    }

    .files-outer .file-search:focus {
      box-shadow: none;
    }

    .file-group-title {
      background-color: #f7f9f8;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #ebeff2;
    }

    .view-files .button-sm.action-icon-delete {
      display: none;
    }

    .view-files.list-view a.file-link {
      color: #353a45;
      display: flex;
      font-size: 14px;
      font-weight: 300;
      flex-direction: row;
      align-items: center;
    }

    .view-files a.file-link:hover,
    .view-files a.file-link:focus,
    .view-files a.file-link:active {
      border: none;
      outline: none;
      text-decoration: none;
    }

    .view-files.grid-view {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &.group-files {
        display: block;

        .file-items-list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }
      }

      a.file-link {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }

      .file-name-tooltip {
        background: #f7f9f9;
        color: #353a45;
        display: block;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        line-height: 1.3;
        position: absolute;
        padding: 2px 5px;
        margin-right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        overflow: visible;
        cursor: pointer;
      }

      .file-size {
        display: block;
        color: #84939a;
      }

      .file-name {
        width: 100%;
        color: #2f333e;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .file-item {
        border: 2px solid #eceff1;
        border-radius: 4px;
        position: relative;
        margin: 5px;
        padding: 0;
        height: 110px;
        width: 110px;
        align-items: flex-start;

        &__new {
          border: 2px solid #2bc97c;
        }

        &.active-state {
          border: 2px solid #039be5;
        }

        .file-buttons {
          display: none;
          background: inherit;
          position: absolute;
          left: 0;
          top: 0;
          width: inherit;
          z-index: inherit;

          .action-icon-view {
            display: none;
          }

          .button {
            background: transparent;
            color: #353a45;

            &:hover {
              color: #039be5;
              background: white;
            }
          }
        }

        &:hover {
          cursor: pointer;

          .file-buttons {
            display: none;

            &__download {
              display: none;
            }

            &__view {
              display: none;
            }

            &__trash {
              display: none;
            }

            .new-file {
              display: none;
            }

            .file-badge {
              display: none;
            }
          }
        }
      }
    }
  }
}

.heading {
  :global {
    .files-heading {
      align-items: center;
      background: #eceff1;
      border-radius: 4px 4px 0 0;
      display: flex;
      color: #2f333e;
      font-size: 18px;
      flex-direction: row;
      height: 45px;
      padding: 0 10px;
      position: relative;

      .f-heading-title {
        display: flex;
        align-items: center;
        font-weight: 400;
        text-transform: uppercase;

        p {
          max-width: 600px;
        }
      }

      .fa-files-o {
        color: #b0bec5;
        margin-right: 10px;
      }

      .control-outer {
        margin-left: auto;

        .file-search {
          border: none;
          position: absolute;
          top: 5px;
          right: 60px;
          width: 200px;
          z-index: 10;
        }

        .button-with-icon {
          background: none;
          margin-left: 25px;
          margin-right: 10px;
          margin-bottom: 3px;
          position: relative;
          height: auto;
          width: 20px;
          z-index: 11;
          font-size: 18px;

          &:active,
          &:focus {
            background: none;
          }
        }
      }
    }

    .hide_field {
      display: none;
    }
  }
}

.header {
  padding: 8px 10px;
  background-color: #f7f9f8;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #eceff1;
}
