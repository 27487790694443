.main {
  :global {
    .modal-body {
      &__files {
        padding: 20px;
        border-top: 1px solid #ebeff2;
      }
    }

    .custom-scroll {
      max-height: calc(100vh - 400px);
    }
  }
}
