@import 'src/assets/scss/color-palette';

.button {
  display: flex;
  align-items: center;
  line-height: 1;
  margin: 0;
  padding: 0;
  font-size: 16px;
  border: none;
  background-color: transparent;

  i,
  p {
    transition: 0.4s;
  }

  i {
    color: $regent-gray;
    margin-right: 5px;
  }

  p {
    color: $tuna;
    text-decoration: underline;
  }

  &:disabled {
    pointer-events: none;

    i,
    p {
      color: $regent-gray;
    }
  }

  &:hover {
    p,
    i {
      color: $cerulean;
    }
  }
}
