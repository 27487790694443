button.btn {
  width: 34px;
  min-width: 34px;
  height: 34px;
  padding: 0;

  i {
    margin: 0;
  }
}
