.modal {
  top: 150px;

  :global {
    .modal-content {
      width: 700px;
      border: none;
    }

    .modal-header {
      padding: 25px;
      background-color: #f7f9f8;
    }

    .modal-body {
      padding: 0;
    }

    .support-modal__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .modal-title {
        font-size: 20px;
        color: #2f333e;
        font-weight: normal;
      }

      .close {
        opacity: 1;
      }

      .fa {
        font-size: 20px;
        font-weight: normal;
        color: #84939a;
      }
    }

    .support-modal__form-body {
      padding: 20px;
    }

    .support-modal__message {
      margin-bottom: 30px;
    }

    .support-modal__footer {
      margin-top: 40px;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #ebeff2;
    }
  }
}
