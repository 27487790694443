@import 'assets/scss/color-palette';

.wrap {
  width: 400px;
  position: fixed;
  top: 60px;
  right: 0;
  height: calc(100vh - 60px);
  background: $white;
  z-index: 10;
  box-shadow: 0 0 10px 1px rgba(38, 50, 56, 0.3);
}

.title {
  padding: 20px;
  min-height: 62px;
  line-height: 1;
  border-bottom: 1px solid $light-gray;
  color: $tuna;
  font-size: 20px;
}

.content {
  max-height: calc(100vh - 210px);
  width: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $tower-gray;
    border-radius: 2px;
  }
}
