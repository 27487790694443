.select_info {
  &__all_visible {
    padding-left: 45px;
    color: #f57f17;
  }

  &__all_exists {
    padding-left: 45px;
    color: #27af6d;
  }
}
