.form {
  padding: 20px;
  display: flex;

  :global {
    .form-group {
      margin: 0;
      max-width: 575px;
      width: 100%;
    }
  }
}
