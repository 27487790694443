.wrap {
  .total_amount {
    display: flex;
    justify-content: space-between;
  }

  .block {
    display: flex;
    margin-bottom: 10px;

    &__data {
      margin-left: 20px;
      min-width: 120px;
    }
  }
}
