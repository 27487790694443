@import 'src/assets/scss/color-palette';

.empty_notes {
  color: $regent-gray;
  background: $snow-drift;
  margin: 20px;
  padding: 10px;
}

.list {
  padding: 0 20px;
  margin: 20px 0;
  max-height: calc(100% - 80px);
  width: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #ebeff2;
  }

  &::-webkit-scrollbar-thumb {
    background: #84939a;
  }
}
