.list {
  :global {
    .settlement-item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 1px solid #eaeef1;
      padding: 10px 0 10px 15px;
      max-height: 100px;
      box-sizing: content-box;

      &.open {
        background: #ebeff2;
      }

      .button-with-icon.button-icon.btn-link {
        width: 34px;
      }

      &__title {
        .iw_cb_shell {
          margin: 0;

          label {
            width: 100%;
            line-height: 21px;
            margin: 3px 0 0 10px;
            white-space: nowrap;
            overflow: hidden;
            text-transform: none;
            font-size: 16px;
          }
        }
      }
    }
  }
}

.expand {
  :global {
    .settlement-item-list {
      display: none;

      &.open {
        display: block;
      }

      &__row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: 1px solid #eaeef1;
        padding: 10px 40px 10px 51px;
      }

      &__col {
        width: 35%;

        &:last-child {
          width: 30%;
        }
      }
    }
  }
}
