.error_notice {
  margin-top: 5px;
  width: 100%;
  position: relative;

  &__text {
    left: 0;
    top: 0;
    position: absolute;
    font-weight: bold;
    color: #ff5253;
  }
}
