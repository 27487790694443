@import 'src/assets/scss/color-palette';

.item {
  display: flex;
  align-items: flex-end;

  > div:nth-child(1) {
    margin-right: 10px;
  }

  :global {
    .button {
      margin: 0 0 15px 10px;
      font-weight: 500;
      border: 1px solid $tower-gray;

      &:hover {
        background-color: $snow-drift;
      }

      &:active {
        background-color: $tower-gray;
      }
    }
  }
}
