.message {
  padding: 15px 20px;
  border-bottom: 1px solid #ebeff2;

  :global {
    .fa {
      color: #ff5253;
      margin-right: 5px;
    }
  }
}
