@import 'color-palette';

/**  ACTION ICON CLASSES  **/
.action-icon {
  font-family: FontAwesome, sans-serif;
  font-size: 18px;
}

.action-icon-view::before {
  content: '\f06e';
}

.action-icon-view-slashed::before {
  content: '\f070';
}

.action-icon-update::before {
  content: '\f040';
}

.action-icon-delete::before {
  content: '\f1f8';
}

.action-icon-restore::before {
  content: '\f0e2';
}

.action-icon-ellipsis::before {
  content: '\f142';
}

.action-icon-grid::before {
  content: '\f009';
}

.action-icon-list::before {
  content: '\f00b';
}

.button {
  background-color: $dodger-blue;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  height: 34px;
  outline: none;
  padding: 0 12px;
  text-transform: uppercase;
  transition: background 0.13s;

  &:hover,
  &:active {
    background-color: $cerulean;
  }

  i {
    vertical-align: middle;
    transition: background 0.13s;
  }

  &.disabled,
  &:disabled,
  &:hover:disabled,
  &:active:disabled {
    background-color: $snow-drift;
    color: $tower-gray;
    cursor: not-allowed;
    pointer-events: none;

    i {
      color: $tower-gray;
    }
  }
}

.input-group-button-after {
  border: 1px solid #ccc;
  border-bottom-left-radius: 0;
  border-left: none;
  border-top-left-radius: 0;
}

.button > a {
  color: #fff;
  display: block;
  height: 100%;
  line-height: 33px;
  padding: 0;
  text-decoration: none;
  width: 100%;
}

a.button {
  display: inline-block;
  line-height: 35px;
  text-align: center;
  text-decoration: none;
}

/* bigger sized button */
.button-bg {
  width: 260px;
}

/* middle sized button */
.button-md {
  width: 160px;
}

.button.hold-for-rc-btn {
  .timer {
    display: inline-block;
    margin-top: 12px;
  }

  .title {
    position: absolute;
    top: 0;
    font-size: 12px;
    margin: auto;
    left: 0;
    right: 0;
  }
}

.view-button {
  margin: 10px 0;
  max-width: 240px;
  width: 90%;

  .fa {
    padding-right: 10px;
  }
}

.button-grey {
  background-color: $porcelain;
  color: $bright-gray;

  .fa {
    color: $regent-gray;
  }

  > a {
    color: $bright-gray;
    text-align: center;
  }

  &:hover {
    background-color: #d4d7d9;
    color: $bright-gray;

    .fa {
      background-color: transparent;
      color: $bright-gray;
    }

    > a {
      color: $dodger-blue;
    }
  }

  &:active {
    background-color: $porcelain;
    color: $bright-gray;

    > a {
      color: $cerulean;
    }
  }
}

.button-red {
  background-color: $sunset-orange;

  &:hover {
    background-color: $jungle-orange;
  }

  &:active {
    background-color: $sunset-orange;
  }
}

.button-green {
  background-color: $shamrock;

  &:hover {
    background-color: $jungle-green;
  }

  &:active {
    background-color: $shamrock;
  }
}

.button-blue {
  background-color: $dodger-blue;
  color: $white;

  i {
    color: $white;
  }

  > a {
    color: $white;
    text-align: center;
  }

  &:active {
    background-color: $cerulean;
    color: $white;

    > a {
      color: $cerulean;
    }
  }

  &:hover {
    i {
      background-color: $cerulean;
      color: $cerulean;
    }

    > a {
      color: $dodger-blue;
    }
  }
}

/* big sized button */
.button-lg {
  font-size: 16px;
  height: 34px;
  line-height: 34px;
  margin: 0 5px;
  min-width: 160px;
  padding: 0 20px;
}

.button-lg-last {
  margin: 0 0 0 10px;
}

/* extra large sized button */
.button-xl {
  font-size: 16px;
  height: 34px;
  line-height: 34px;
  margin: 0 5px;
  padding: 0;
  width: 330px;
}

.button-big {
  padding-left: 35px;
  padding-right: 35px;

  @media only screen and (max-width: 1200px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.button-normal {
  text-transform: none;

  i {
    margin-right: 10px;
  }
}

.button-block {
  position: relative;
  cursor: pointer;

  .button-block__list {
    top: 25px;
    left: 0;
    position: absolute;
    min-width: 185px;
    background-color: #fff;
    box-shadow: 0 0 4px 0 rgba(#000, 0.1);
    border-radius: 2px;
    border: 1px solid #ebeff2;
    z-index: 150;
    visibility: hidden;
    transition: all 0.2s;

    .button {
      white-space: nowrap;
      text-align: left;
      background-color: #f7f9f8;

      &:hover {
        background-color: #d4d7d9;
      }
    }
  }

  &.active {
    cursor: default;

    .button-block__list {
      visibility: visible;

      .button {
        .fa {
          font-size: 18px;
        }
      }
    }
  }
}

.dropdown-menu {
  background-color: $white;
  border: 1px solid $snow-drift;
  min-width: 200px;
  padding: 0;
}

.dropleft .dropdown-toggle {
  width: 34px;
  height: 34px;
  line-height: 1.1;
  border-radius: 4px;
  background: transparent;
  color: #84939a;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &::before {
    display: none;
  }

  &:active,
  &.active {
    background: transparent;
    color: #353a45;
  }
}

.file_upload {
  font-size: 1em;        /* example */
  height: 2em;           /* example */
  line-height: 2em;
  overflow: hidden;
  position: relative;
}

.file_upload.focus {
  box-shadow: 0 0 5px rgba(0, 30, 255, 0.4);
}

.file_upload > button {
  float: right;
  height: 100%;
  width: 8em            /* example */
}

.file_upload:hover > button {
  background: #6200bd;
  text-shadow: #5d00b3 0 -1px 0;
}

.file_upload:active > button {
  background: #5d00b3;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3) inset;
}

.file_upload input[type=file] {
  cursor: pointer;
  height: 100%;
  left: 0;
  letter-spacing: 10em;     /* IE 9 fix */
  opacity: 0;
  position: absolute;
  top: 0;
  -ms-transform: scale(20);
  transform: scale(20); /* IE 9 fix */
  width: 100%;
}

.file-form {
  overflow: hidden;
  padding: 2px;
  position: relative;
  text-align: left;
  vertical-align: middle;
}

.file-form .select-btn {
  float: right;
  line-height: 20px;
  overflow: hidden;
  padding: 3px 6px;
  text-align: center;
  vertical-align: middle;
  width: 108px;
}

.file-form #upload {
  filter: alpha(opacity=0);
  font-size: 150px;
  height: 30px;
  left: 0;
  -moz-opacity: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
}

.file-form #file-form-label {
  background-color: #fff;
  float: left;
  line-height: 24px;
  overflow: hidden;
  padding: 2px 12px;
  text-align: center;
  vertical-align: middle;
}

.btn.disabled,
.btn[disabled] {
  cursor: not-allowed;
}

.btn-custom {
  background-color: #eceff1;
  color: #84939a;
  font-size: 16px;
  height: 34px;
  margin: 0 3px;
  padding: 0;
  border-radius: 4px;
  width: 34px;

  .dropdown-menu {
    box-shadow: 1px 1px 4px 1px #ebeff2;
  }
}

.btn-custom:hover {
  color: #40c4ff;
}

.btn-custom:active {
  background-color: #b0bec5;
  box-shadow: none;
  color: #353a45;
}

.btn-group.open .btn-custom {
  background-color: $tower-gray;
  box-shadow: none;
  color: $bright-gray;
}

.btn-group {
  &__filter {
    display: flex;
    justify-content: flex-end;

    .btn {
      padding: 3px 10px;
      font-size: 14px;
      background: transparent;
      border-color: #b0bfc6;
      box-shadow: none;

      &:not(.disabled):hover {
        color: #039be5;
      }

      &:not(.disabled):focus {
        outline: none;
      }

      &:not(.disabled):active,
      &.active {
        background: #fff;
        border-color: #b0bfc6;
        box-shadow: none;

        &:focus {
          background: #fff;
          border-color: #b0bfc6;
          color: #039be5;
        }
      }
    }
  }
}

.custom-link {
  color: $bright-gray;
  display: block;
  padding: 10px 12px;
  text-transform: uppercase;

  .action-icon {
    color: $regent-gray;
    display: inline-block;
    margin-right: 8px;
    min-width: 23px;
  }

  &.disabled {
    color: rgb(132, 147, 154);
    cursor: not-allowed;
    text-decoration: none;
    outline: none;
    background-color: transparent;
    pointer-events: none;

    .action-icon {
      color: rgb(132, 147, 154);
    }
  }
}

a.custom-link:hover {
  background-color: $snow-drift;
  color: inherit;
  text-decoration: none;
}

a.custom-link:hover .action-icon {
  color: $cerulean;
}

a.custom-link:active {
  background-color: $tower-gray;
  text-decoration: none;
}

a.custom-link:active .action-icon {
  color: $bright-gray;
}

.btn-link {
  background-color: transparent;
  color: #84939a;
  text-decoration: none;
  border: none;

  &:hover,
  &:focus {
    color: $bright-gray;
  }
}

.btn-select {
  background-color: #f7f9f8;
  box-shadow: none;
  color: #b0bfc6;
  font-size: 16px;
  min-width: 120px;
  text-align: left;
  transition: all 0.2s;

  &:hover,
  &:focus {
    background-color: #f7f9f8;
    color: #b0bfc6;
    opacity: 0.8;
    outline: 0;
  }

  &.active,
  &:active {
    background-color: #039be6;
    box-shadow: none;
    color: #fff;

    &:hover,
    &:focus {
      background-color: #039be6;
      color: #fff;
      opacity: 0.8;
      outline: 0;
    }
  }
}

.btn-xs {
  align-items: center;
  display: inline-flex;
  height: 34px;
  justify-content: center;
  width: 34px;
}

/******************** MainButton **************************/

.button,
a.button {
  background-color: transparent;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  height: 34px;
  outline: none;
  padding: 0 12px;
  position: relative;
  text-transform: uppercase;
  transition: background 0.13s;
  vertical-align: middle;
  cursor: pointer;

  &.rotate-90 {
    transform: rotate(-90deg);
  }

  &.no-uppercase {
    text-transform: none;
  }

  .count-notes {
    align-items: center;
    background: #fff;
    border-radius: 3px;
    display: inline-flex;
    font-size: 14px;
    height: 23px;
    justify-content: center;
    margin-left: 10px;
    width: 23px;
  }

  &.mb15 {
    margin-bottom: 15px;
  }

  &-small {
    min-width: 130px;
  }

  &-medium {
    min-width: 160px;
  }

  &-large {
    min-width: 200px;
  }

  &-full-width {
    min-width: 100%;
  }

  &:disabled,
  &:hover:disabled,
  &:active:disabled {
    background-color: $snow-drift;
    color: $tower-gray;
    cursor: not-allowed;

    i {
      color: $tower-gray;
    }
  }

  &-icon {
    color: $bright-gray;
    display: inline-block;
    height: 34px;
    margin: 0 3px;
    padding: 0;
    text-align: center;
    vertical-align: middle;
    width: 34px;

    i {
      color: $regent-gray;
      font-size: 18px;
    }

    &:hover {
      i {
        color: $cerulean;
      }
    }

    &:active,
    &.active {
      i {
        color: $bright-gray;
      }
    }
  }

  &-with-icon {
    color: $bright-gray;

    i {
      color: $regent-gray;
      font-size: 18px;
      margin-right: 10px;
    }

    &.button-icon {
      i {
        margin: 0;
      }

      &.btn-link {
        background: transparent;
        padding: 0 5px;
        width: auto;
      }

      &.btn-icon-small {
        width: 15px;
      }
    }

    &:hover {
      i {
        color: $cerulean;
      }
    }

    &:active,
    &.active {
      i {
        color: $bright-gray;
      }
    }
  }

  &-blue {
    background-color: $dodger-blue;

    &:hover {
      background-color: rgba($dodger-blue, 0.8);
    }

    &:active,
    &.active {
      background-color: $cerulean;
    }
  }

  &-yellow {
    background-color: $yellow-orange;

    &:hover {
      background-color: rgba($yellow-orange, 0.8);
    }

    &:active,
    &.active {
      background-color: $orange;
    }
  }

  &-green {
    background-color: $shamrock;

    &:hover {
      background-color: rgba($shamrock, 0.8);
    }

    &:active,
    &.active {
      background-color: $jungle-green;
    }
  }

  &-grey {
    background-color: $porcelain;
    color: $bright-gray;

    &:hover {
      background-color: rgba($porcelain, 0.8);
    }

    &:active,
    &.active {
      background-color: $tower-gray;
    }
  }

  &-dark-grey {
    background-color: $tower-gray;
    color: $bright-gray;

    &:hover {
      background-color: rgba($tower-gray, 0.8);
    }

    &:active,
    &.active {
      background-color: $porcelain;
    }
  }

  &-red {
    background-color: $sunset-orange;

    &:hover {
      background-color: rgba($sunset-orange, 0.8);
    }

    &:active,
    &.active {
      background-color: $jungle-orange;
    }
  }

  &-white {
    background-color: #fff;
    color: $bright-gray;

    &:hover {
      background-color: rgba(#fff, 0.8);
      color: $bright-gray;
    }

    &:active,
    &.active {
      background-color: $tower-gray;
      color: $bright-gray;
    }

    &:disabled,
    &:hover:disabled,
    &:active:disabled {
      background-color: #fff;
      color: $tower-gray;
      cursor: not-allowed;

      i {
        color: $tower-gray;
      }
    }
  }

  &.button-xs {
    height: 22px;
    width: 25px;
    padding: 0 5px;

    &.button-with-icon i {
      font-size: 12px;
    }
  }

  /* small sized button */
  &.button-sm {
    background-color: $porcelain;
    color: $regent-gray;
    font-size: 16px;
    height: 34px;
    margin: 0 5px;
    padding: 0;
    width: 34px;
  }

  &.button-sm > a {
    color: $bright-gray;
    text-align: center;
  }

  &.button-sm:hover {
    background-color: $porcelain;
    color: $cerulean;
  }

  &.button-sm:active {
    background-color: $tower-gray;
    color: $bright-gray;
  }

  &.button-sm:hover > a {
    color: $dodger-blue;
  }

  &.button-sm:active > a {
    color: $cerulean;
  }

  &.button-margin {
    margin-bottom: 5px;
    margin-left: 10px;
  }
}

.upload-button {
  .button {
    overflow: hidden;
    position: relative;

    input[type='file'] {
      background: white;
      cursor: inherit;
      display: block;
      filter: alpha(opacity=0);
      font-size: 100px;
      min-height: 100%;
      min-width: 100%;
      opacity: 0;
      outline: none;
      position: absolute;
      right: 0;
      text-align: right;
      top: 0;
    }
  }
}

.search-btn {
  position: absolute;
  margin-right: 0;
  top: 1px;
  height: 32px;
  right: 5px;
  z-index: 111;
  background-color: transparent !important;

  .fa {
    color: #b0bec5;
  }
}

@import "redesign/custom-buttons";
