@import "color-palette";

.pagination-outer {
  background: $white;
  border-radius: 0 0 4px 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px;
  width: 100%;

  &.withoutBackground {
    padding: 5px 0;
    background: transparent;
    border-radius: 0;
  }

  .pagination {
    margin: 0;
    line-height: 1;
    height: 34px;

    li {
      a,
      a:active,
      a:focus,
      .page-link {
        background: $snow-drift;
        border: 1px solid $white;
        color: $bright-gray;
        font-size: 16px;
        font-weight: 400;
        height: 34px;
        line-height: 32px;
        padding: 0 5px;
        text-align: center;
        min-width: 40px;
        box-shadow: none;
        margin-left: inherit;
      }

      &:hover {
        a {
          background: $lily-white;
        }
      }

      a span[aria-label='First'],
      a span[aria-label='Previous'],
      a span[aria-label='Next'],
      a span[aria-label='Last'] {
        font-size: 24px;
        line-height: 30px;
      }

      &.disabled {
        a {
          color: $tower-gray;
        }
      }

      &.active {
        a {
          background: $porcelain;
        }
      }

      &.active:hover a {
        background: $porcelain;
        border: 1px solid $white;
        color: $bright-gray;
      }
    }
  }

  .page-item.active .page-link {
    border-color: #fff;
    background-color: #ebeff2;
    color: #2f333e;
  }
}

.per-page,
.per-page:focus {
  background: $snow-drift;
  border: 1px solid $porcelain;
  border-radius: 4px;
  box-shadow: none;
  color: $bright-gray;
  font-size: 16px;
  font-weight: 400;
  height: 34px;
  line-height: 34px;
  margin-right: 20px;
  padding-left: 17px;
  text-align: center;
  width: 130px;
}

.per-page option:hover {
  background: $porcelain;
}

.per-page-outer {
  position: relative;
}

.per-page-outer .per-page-label {
  background: #f7f9f9;
  color: #b0bec5;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  pointer-events: none;
  position: absolute;
  right: 21px;
  text-align: center;
  top: 2px;
  width: 35px;
}

.pagination-label {
  color: $regent-gray;
  font-size: 14px;
  line-height: 34px;
  margin-right: 10px;
}

.container-showing {
  margin: 20px 0 0;

  p {
    color: #37474f;
    font-weight: 300;

    span {
      font-weight: 400;
    }
  }
}
