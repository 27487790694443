@import "color-palette";

table {
  tbody {
    tr {
      &:nth-last-child(2) {
        .btn-group {
          .dropdown-menu {
            top: inherit;
            bottom: 100%;
          }
        }
      }

      &:nth-last-child(1) {
        .btn-group {
          .dropdown-menu {
            top: inherit;
            bottom: 100%;
          }
        }
      }
    }
  }
}

.list-table thead > tr > th {
  background: $snow-drift;
  border-bottom: 1px solid $porcelain;
  color: $regent-gray;
  font-size: 14px;
  font-weight: 500;
  line-height: 44px;
  white-space: nowrap;

  &:first-child {
    border-radius: 4px 0 0;
  }

  &:last-child {
    border-radius: 0 4px 0 0;
  }
}

.list-table > tbody > tr > td:not(.empty) {
  border: 1px solid $porcelain;
  font-size: 16px;
  padding: 8px 20px;
  vertical-align: middle;
  white-space: nowrap;
}

.list-table > tbody > tr > td:first-child {
  border-left: none;
}

.list-table > tbody > tr > td:last-child {
  border-right: none;
}

.table-row-hover {
  tbody {
    background-color: $white;
  }
}

.table-row-hover tbody tr:hover td:not(.empty),
.table-row-hover tbody tr:hover th {
  background-color: $snow-drift;
}

.table-zebra {
  tbody {
    tr:nth-child(2n+1) {
      background: #d0d9dd;
    }
  }
}

.table-row-disabled {
  color: $regent-gray;

  i,
  span,
  strong,
  button,
  a {
    color: $regent-gray;
  }
}

.table-colorable-expand {
  &.standard-table-style {
    .table {
      tbody {
        tr {
          &.table-row-expanded {
            background-color: $cerulean;
            color: $white;

            i,
            div,
            span,
            strong,
            a {
              color: $white;
            }

            button,
            button div,
            button i {
              color: $cerulean;
            }

            button.btn-table i {
              color: $white;
            }

            button.dropdown-toggle span {
              color: $regent-gray;
            }

            td:not(.empty) {
              div {
                color: $white;
              }

              button div i {
                color: $cerulean;
              }

              border-color: $white;
              background-color: $cerulean;
            }

            .custom-link {
              color: #353a45;
              display: block;
              padding: 10px 12px;
              text-transform: uppercase;

              .action-icon i {
                color: #87969e;
              }
            }

            .custom-link.disabled {
              color: #84939a;
              cursor: not-allowed;
              text-decoration: none;
              outline: none;
              background-color: transparent;
              pointer-events: none;

              .action-icon i {
                color: #84939a;
              }
            }
          }
        }
      }
    }
  }

  .standard-table-style {
    .table {
      thead {
        tr {
          th {
            padding: 5px 10px;

            &:first-child,
            &:last-child {
              border-radius: 0;
            }
          }
        }
      }
    }
  }
}
