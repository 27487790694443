@import 'src/assets/scss/color-palette';

.container {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;

  &__counters {
    color: $regent-gray;
    margin-right: 40px;
    padding: 10px 0;
  }

  &__arrows {
    display: flex;
    grid-gap: 30px;
    color: $regent-gray;
    font-size: 20px;
    line-height: 1;
    cursor: pointer;

    span {
      padding: 10px;
    }
  }
}
