@import 'src/assets/scss/color-palette';

.container {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  &.low {
    background: $loafer;
  }

  &.medium {
    background: $seashell-peach;
  }

  &.high {
    background: $fair-pink;
  }
}
