.block {
  border: 1px solid #ebeff2;
  border-radius: 5px;
  overflow: hidden;

  :global {
    .attach-file {
      font-size: 16px;

      &__header {
        padding: 3px 2px 3px 12px;
        background-color: #ebeff2;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .control-outer {
          position: relative;

          .file-search {
            border: none;
            position: absolute;
            top: 0;
            right: 42px;
            width: 200px;
            z-index: 10;

            &.hide_field {
              display: none;
            }
          }

          .button-sm {
            background: none;
            margin-left: 20px;
            position: relative;
            height: auto;
            width: 20px;
            z-index: 11;
            font-size: 18px;

            &:active,
            &:focus {
              background: none;
            }
          }
        }
      }
    }
  }
}
