@import 'src/assets/scss/color-palette';

.badge {
  position: absolute;
  left: 3px;
  top: 3px;
  width: 20px;
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 3px;
  background-color: $onahau;
}
