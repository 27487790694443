.item {
  padding: 20px;

  :global {
    .fa-exclamation-circle {
      color: #ffab40;
      margin-right: 5px;
    }

    ul {
      margin-top: 10px;
      margin-bottom: 10px;

      li {
        &::before {
          content: '-';
          margin-right: 5px;
        }
      }
    }

    .badge {
      margin-left: 5px;
      background-color: #ebeff2;
    }
  }
}

.custom_scroll {
  &__more_settlements {
    max-height: calc(185px);
    width: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #ebeff2;
    }

    &::-webkit-scrollbar-thumb {
      background: #84939a;
    }
  }
}

.loading {
  border-top: 1px solid #ebeff2;
  border-bottom: 1px solid #ebeff2;
  background-color: #f7f9f8;

  &:hover {
    background-color: #ebeff2;
  }

  > div {
    margin: 10px 0;
  }

  &__not_active {
    background-color: #ebeff2;

    > div {
      margin: 2px 0;
    }
  }

  :global {
    .second-link {
      width: 100%;
      display: inline-block;
      background-image: none;
      text-align: center;
      height: 100%;
      color: #7a8185;

      &:hover {
        color: #7a8185;
      }
    }
  }
}
