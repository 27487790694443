@import 'assets/scss/color-palette';

.wrap {
  display: flex;
  align-items: center;

  :global {
    [class*='option--is-selected'] {
      span.symbol {
        color: $white;
      }
    }
  }
}

.symbol {
  font-size: 16px;
  color: $cerulean;
  margin-right: 5px;
}
