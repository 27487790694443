@import 'src/assets/scss/color-palette';

.large_size {
  [class$="-control"] {
    min-width: 205px;
  }
}

.select_component {
  [class$="__multi-value"] {
    background-color: $white;
    border: 1px solid $tower-gray;
  }

  [class$="__multi-value__label"] {
    font-size: 14px;
  }

  [class$="__multi-value__remove"] {
    &:hover {
      background: transparent;
      cursor: pointer;
    }
  }

  [class$="option--is-disabled"] {
    margin-left: 5px;
    color: $regent-gray;
  }

  [class$="__dropdown-indicator"],
  [class$="__clear-indicator"] {
    padding: 5px;
    color: $regent-gray;

    &:hover {
      color: $cerulean;
    }
  }

  [class$="-indicatorContainer"] {
    padding: 4px;
  }

  [class$="email-select-value-container"] {
    display: flex;
    border: 1px solid #b0bec6;
    background-color: #fff;
    padding-left: 5px;
    margin: 2px;

    [class$="__multi-value"] {
      border: none;
    }
  }

  [class$="-control"] {
    background: $snow-drift;
    border-color: $tower-gray;
    min-height: 34px;
    min-width: 130px;
    font-size: 14px;
    line-height: 1;
  }

  [class$="-control--is-focused"],
  [class$="-control--menu-is-open"] {
    min-width: 130px;
    min-height: 34px;
  }

  [class$="-control--is-disabled"] {
    background-color: #ebeff2;
    border: 1px solid transparent;
    box-shadow: none;
    min-height: 34px;
    color: #353a45;
    min-width: 130px;
    line-height: 1;
  }

  [class*="--is-disabled"] {
    background-color: #ebeff2;
  }

  [class$="__single-value--is-disabled"] {
    color: #353a45;

    input {
      width: 0.5px;
    }
  }

  [class$="__single-value"] {
    line-height: 1.2;
  }

  [class$="-control--is-focused"] {
    background: $snow-drift;
    border-color: #039be6;
    min-height: 34px;
    font-size: 14px;
    min-width: 130px;
    line-height: 1;
    box-shadow: 0 0 3px 2px rgba(3, 155, 230, 0.15);

    &:hover {
      border-color: #039be6;
      box-shadow: 0 0 3px 2px rgba(3, 155, 230, 0.15);
    }
  }

  [class*="option"]:hover {
    background-color: #deebff;
    color: inherit;
  }

  [class*="option--is-focused"] {
    background: #ebeff2;

    &:active {
      background: #f7f9f8;
    }
  }

  [class*="option--is-selected"],
  [class*="option--is-selected"]:hover {
    background: #039be6;
  }

  [class$="-menu"] {
    z-index: 1000;
    font-size: 14px;

    [class*="option"],
    [class*="option--is-focused"],
    [class*="option--is-selected"] {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:active {
        background: #ecf9ff;
      }

      > div {
        display: flex;
        flex-direction: column;

        > span {
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      border-bottom: 1px solid #ebeff2;
    }
  }
}

.select_component.error {
  [class$="-control"] {
    background: lighten($sunset-orange, 30%);
    border-color: $sunset-orange;
  }
}

.redesign {
  [class$="-control"] {
    background: $snow-drift;
    border-color: #7a8185;
    max-height: 24px;
    min-height: 24px;
    min-width: 110px;
    font-size: 14px;
    line-height: 1;

    [class$="-is-focused"] {
      border-color: #039be6;
      box-shadow: 0 0 3px 2px rgba(3, 155, 230, 0.15);
    }
  }

  [class$="__multi-value"] {
    background-color: #e5e8e9;
    border: none;
    height: 18px;
    line-height: 17px;
    font-size: 12px;
    margin-right: 3px;
  }

  [class$="__multi-value__label"] {
    height: 18px;
    line-height: 17px;
    font-size: 12px;
    padding: 0 3px;
  }

  [class*="--is-disabled"] {
    background-color: #ebeff2;
  }

  [class*="__value-container"] {
    padding: 0 5px;
  }

  [class$="-placeholder"] {
    font-size: 12px;
    line-height: 1;
  }

  [class$="__input"] {
    line-height: 13px;
    font-size: 12px;
  }

  [class$="-1hwfws3"] {
    padding: 0 8px;
  }

  [class$="-indicatorContainer"] {
    padding: 0;
  }

  [class$="-control--is-focused"],
  [class$="-control--menu-is-open"] {
    min-width: 110px;
    min-height: 24px;
  }

  [class$="-control--is-focused"] {
    background: $snow-drift;
    border-color: #039be6;
    min-height: 24px;
    font-size: 14px;
    min-width: 110px;
    line-height: 1;
    box-shadow: 0 0 3px 2px rgba(3, 155, 230, 0.15);

    &:hover {
      border-color: #039be6;
      box-shadow: 0 0 3px 2px rgba(3, 155, 230, 0.15);
    }
  }

  [class$="-control--is-disabled"] {
    background-color: #ebeff2;
    border: 1px solid transparent;
    box-shadow: none;
    min-height: 24px;
    color: #353a45;
    min-width: 110px;
    line-height: 1;
  }

  &.select_component.error {
    [class$="-control"] {
      background: $snow-drift;
      border-color: $sunset-orange;
    }
  }

  [class$="-menu"] {
    font-size: 13px;
  }
}
