.text_input {
  :global {
    .clear-btn,
    .submit-btn {
      position: absolute;
      right: 0;
      top: 25px;
      z-index: 10;

      button {
        background: transparent;
        width: 30px;
        height: 30px;
      }
    }
  }
}
