//-------------------------------------
// Roboto
//-------------------------------------
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Comfortaa:300,400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

@font-face {
  font-family: "LemonMilk";
  src: url('../vendor/fonts/LemonMilk/LEMONMILK.ttf');
}
