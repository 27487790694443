@import 'src/assets/scss/color-palette';

.search_fields {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  grid-gap: 10px;
  border-bottom: 1px solid $light-gray;

  :global {
    .form-group {
      &:nth-child(1) {
        width: 120px;
      }

      &:nth-child(2) {
        width: 120px;
      }
    }
  }

  &__dates {
    display: flex;
    align-items: center;

    >span {
      display: block;
      margin-bottom: 15px;
      padding: 3px;
      line-height: 1;
    }
  }

  button {
    text-transform: none;
  }
}
