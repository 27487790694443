.clock-container {
  display: flex;
  align-items: center;
  margin: 0 20px;
}

.time-container {
  font-size: 36px;
  font-weight: 500;
  margin-right: 10px;
}

.timezone-container {
  font-size: 32px;
}
