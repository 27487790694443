@import 'assets/scss/color-palette';

.tn-filter {
  box-shadow: 0 0 10px 1px rgba(38, 50, 56, 0.05);
  background: $white;
  border-radius: 5px;
  padding-bottom: 1px;

  .button {
    text-transform: none;

    &-icon {
      min-width: 34px;
    }
  }

  .right-btn-block {
    margin-left: auto;

    .apply {
      margin: 0 10px;
    }
  }

  .payload-min {
    min-width: 170px;
    position: relative;

    input[type='number'] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;
      padding-right: 45px;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }

    .unit {
      background: $light-gray;
      color: $regent-gray;
      height: calc(100% - 2px);
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      padding: 5px 10px 5px 10px;
      position: absolute;
      display: inline-flex;
      top: 1px;
      right: 1px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    color: $regent-gray;
    font-size: 16px;
  }

  &__item {
    display: flex;
    padding: 10px 20px;

    &_title {
      width: 25%;
      display: flex;
      align-items: center;

      &_width {
        width: 18%;
      }

      .icon {
        width: 45px;
        font-size: 32px;
        color: $tower-gray;
        margin-right: 5px;

        &.main {
          color: $cerulean;
        }

        &.home {
          color: $shamrock;
        }
      }

      .title {
        font-size: 20px;

        .subtitle {
          display: block;
          color: $regent-gray;
          font-size: 16px;
        }
      }
    }

    &_content {
      width: 82%;
      display: flex;
      align-items: center;

      .first-content-item {
        width: 100%;
        max-width: 340px;

        .flex-block {
          align-items: center;
        }

        &.big {
          max-width: 420px;
        }

        &.small {
          max-width: 255px;
        }

        &.mw-500 {
          max-width: 500px;
        }
      }

      .form-group {
        margin-bottom: 0;
      }
    }

    .form-control {
      font-size: 16px;
    }

    .sep {
      margin: 0 5px;
    }

    .input-group-btn .button {
      position: absolute;
      right: 0;
      top: 1px;
      z-index: 300;
      height: 32px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      text-transform: lowercase;
      color: $regent-gray;
      font-size: 14px;
    }
  }

  &__items {
    &_info {
      margin: 2px;
      border: 2px solid $light-gray;
      border-radius: 4px;

      .tn-filter__item {
        padding: 10px 16px;
        border-bottom: 2px solid $light-gray;

        &:last-child {
          border-bottom: none;
        }

        &_title {
          .icon {
            font-size: 24px;
          }
        }
      }
    }
  }

  .check-bg {
    background-color: $snow-drift;
    text-align: center;
    padding: 6px 0;
    border-radius: 4px;
    min-height: 36px;
    height: 34px;

    .iw_cb_shell {
      margin-top: 0;

      span.cb_square {
        margin-right: 8px;
      }
    }
  }

  .submit-btn {
    margin-left: auto;
  }

  label {
    font-weight: 400;
  }

  .Select {
    min-width: 220px;
  }

  .toggle-input {
    max-width: 160px;
    min-width: 140px;
    z-index: 0;
  }

  .datepicker-no-form-group .react-datepicker-wrapper {
    max-width: 180px;
  }

  .driver-content-item {
    h2 {
      font-size: 20px;
    }

    .flex-block {
      align-items: center;

      & > * {
        margin: 0 5px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &.toggle {
    .tn-filter__items {
      display: none;
    }
  }
}
