@import 'assets/scss/color-palette';

.scroll {
  max-height: calc(100vh - 223px);
  width: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $light-gray;
  }

  &::-webkit-scrollbar-thumb {
    background: $regent-gray;
  }
}
