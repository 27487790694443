@import 'src/assets/scss/color-palette';

.option {
    width: -moz-available;
    width: -webkit-fill-available;
    position: relative;
    display: flex;
    align-items: center;
    border: none;
    background: none;
    min-height: 20px;
    padding: 7px 20px;
    cursor: pointer;

    & > label {
        cursor: pointer;
        white-space: nowrap;
    }

    &:hover {
        background-color: $light-porcelain;
    }

    .square {
        position: relative;
        min-width: 20px;
        min-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $tower-gray;
        border-radius: 3px;
        margin-right: 5px;
      }
}