@import "src/assets/scss/color-palette";

.counter {
  vertical-align: middle;
  display: inline-block;
  width: 23px;
  height: 23px;
  //border: 1px solid #b0bfc6;
  border: 1px solid $tower-gray;
  text-align: center;
  margin-right: 15px;
  border-radius: 4px;
}
