@import 'src/assets/scss/color-palette';

.main {
  position: relative;
  height: auto;

  .list {
    height: calc(100vh - 175px);
    padding-bottom: 160px;
  }

  .list__view {
    max-height: 300px;
    padding-bottom: 0;
  }

  .form {
    position: absolute;
    width: calc(100% - 40px);
    bottom: 0;
    margin: 20px 20px 0;

    :global {
      .btn-group__filter {
        justify-content: flex-start;

        .btn,
        .btn.active {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 150px;
          color: $tuna;
          font-family: Roboto, Arial, sans-serif;
          font-size: 14px;
          border-radius: 0;
          padding: 5px 10px;
          background-color: transparent;
          border: 1px solid transparent;
          border-bottom: none;
          font-weight: 600;

          &:hover {
            background-color: transparent;
          }
        }

        .btn:not(:last-child) {
          margin-right: 3px;
        }

        .btn:not(.disabled):hover {
          color: $cerulean;
          border: 1px solid $tower-gray;
          border-bottom: none;
        }

        .btn:not(.disabled):active {
          color: $cerulean;
          background-color: $porcelain;
          border: 1px solid $tower-gray;
          border-bottom: none;
        }

        .btn-default:not(:disabled):not(.disabled).active {
          background-color: $snow-drift;
          border: 1px solid $tower-gray;
          border-bottom: none;
          color: $cerulean;
        }
      }
    }

    .bottom {
      display: flex;
      justify-content: space-between;
    }
  }

  .group {
    opacity: 1;
    display: flex;
    align-items: center;

    .title {
      text-transform: uppercase;
      font-size: 14px;
      margin: 20px 20px 0;
    }

    :global {
      .btn-group__filter {
        margin: 20px 20px 0;
        justify-content: flex-start;

        .btn,
        .btn.active {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 65px;
          height: 25px;
          color: $tuna;
          font-family: Roboto, Arial, sans-serif;
          font-size: 14px;
          background-color: $porcelain;
          border: none;

          &:hover {
            background-color: $alto;
          }
        }

        .btn:not(:last-child) {
          margin-right: 3px;
        }

        .btn:not(.disabled):hover {
          color: $tuna;
        }

        .btn:not(.disabled):active {
          color: $tuna;
          background-color: $porcelain;
        }

        .btn-default:not(:disabled):not(.disabled).active {
          background-color: #bdd7ed;
          color: $tuna;
        }
      }
    }
  }
}
