@import 'src/assets/scss/color-palette';

.list {
  padding: 0 20px;
  margin: 20px 0;
  max-height: calc(100% - 80px);
  width: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #ebeff2;
  }

  &::-webkit-scrollbar-thumb {
    background: #84939a;
  }

  &__item {
    border: 1px solid #f7f9f8;
    background: #f7f9f8;
    padding: 15px;
    margin-bottom: 10px;

    &_header {
      display: flex;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 5px;

      &_link {
        margin-right: 5px;
      }

      &_desc {
        color: #84939a;
      }
    }

    &_content {
      color: #2f333e;
      font-size: 16px;
      word-break: break-all;
      white-space: normal;
    }
  }
}
