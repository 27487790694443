.faPlaneIcon {
  color: #039BE6;
}

.checkboxContainer {
  display: flex;
  justify-content: right;
  grid-gap: 20px;
  margin-top: 25px;
  margin-left: 10px;
}

.label {
  text-transform: capitalize;
  font-weight: bold;
}
