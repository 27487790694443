.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  :global {
    .label-text {
      max-width: 400px;
    }

    .sent-status {
      display: flex;
      width: 100%;
      align-items: center;
      padding: 0 20px;
      justify-content: space-between;

      p {
        margin: 0 20px;
      }

      .not-sent {
        color: #84939a;
      }
    }
  }
}
