.form {
  :global {
    .modal-body {
      &__select-info {
        border-bottom: 1px solid #ebeff2;
        border-top: 1px solid #ebeff2;
        position: absolute;
        width: 100%;
        z-index: 200;
        bottom: 74px;

        > div {
          padding: 20px;
          border-bottom: 1px solid #fff;
          border-top: 1px solid #fff;
          background: #e2f9f0;

          &.no-invoices {
            background: #ffeced;
          }
        }
      }

      &__main {
        .invoices-group {
          padding-bottom: 90px;
        }
      }
    }
  }
}
