.item {
  :global {
    .main-header,
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    }

    .main-header {
      background: #eaeef1;
    }

    .empty-content {
      border: 1px solid #ebeff2;
      padding: 10px;
      font-size: 14px;
      font-style: italic;
      color: #84939a;
    }

    .header {
      background: #f6f9f8;
      border: 1px solid #ebeff2;
      border-top: 0;
    }
  }
}
