@import 'src/assets/scss/color-palette';

.actions {
  :global {
    .button-block {
      text-align: right;
    }

    div.button-block__list {
      top: 0;
      left: unset;
      right: 20px;
      position: absolute;
      min-width: 120px;
      background-color: $white;
      box-shadow: 0 0 4px 0 rgba($black-strong, 0.1);
      border-radius: 4px;
      border: none;
      z-index: 150;
      visibility: hidden;
      overflow: hidden;
      transition: all 0.2s;

      .button {
        white-space: nowrap;
        text-align: left;
        background-color: $white;
        border-radius: 0;
        text-transform: none;

        &:hover {
          background-color: $light-gray;
        }
      }
    }
  }

  .button {
    background: transparent;
    width: 34px;
  }
}
