.items {
  :global {
    .file-item {
      align-items: center;
      border-top: 1px solid #eceff1;
      display: flex;
      flex-direction: row;
      height: 55px;
      padding: 10px;

      .file-buttons {
        margin-left: auto;
        white-space: nowrap;
      }
    }

    .new-file {
      border: 1px solid #2bc97c;
      border-radius: 4px;
      color: #2f333e;
      font-size: 14px;
      padding: 4px 7px;
      margin-right: 5px;
      white-space: nowrap;
    }

    .file-badge {
      border: 1px solid #ebeff2;
      border-radius: 4px;
      background-color: #ebeff2;
      font-size: 14px;
      padding: 4px 7px;
      margin-right: 5px;
      white-space: nowrap;
      line-height: 1;
    }

    .file-name-tooltip {
      color: #353a45;
      font-size: 16px;
      line-height: 1;
      font-weight: 300;
      flex-direction: row;
      align-items: center;
      margin-right: 20px;
      overflow: hidden;
    }

    .file-size {
      display: none;
      cursor: default;
    }

    .file-name {
      width: 100%;
      color: #2f333e;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
