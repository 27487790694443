.item {
  border: 1px solid #ebeff2;
  border-radius: 5px;
  margin-top: 20px;

  :global {
    .iw_cb_shell span.cb_square {
      vertical-align: top;
      margin: 3px;
    }

    .header {
      background: #ebeff2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 0 5px 10px;
      min-height: 45px;

      >div {
        &:last-child {
          min-width: 180px;
          text-align: right;
          padding-right: 5px;
        }
      }
    }

    .main {
      display: none;

      &.open {
        display: block;
      }
    }

    .settlements-group-table {
      thead {
        tr {
          th {
            background: #f7f9f8;
            padding: 10px;
            color: #84939a;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      tbody {
        tr {
          td {
            vertical-align: top;
            border: 1px solid #ebeff2;
            padding: 10px;
            position: relative;

            &:nth-child(1) {
              width: 130px;
              border-left: none;
            }

            &:nth-child(2) {
              width: 200px;
            }

            &:nth-child(3) {
              width: 250px;

              > div {
                width: 220px;
              }
            }

            &:nth-child(4) {
              width: 80px;
            }

            &:nth-child(5) {
              width: 170px;
            }

            &:nth-child(6) {
              white-space: nowrap;
              padding-right: 30px;
            }

            .payment {
              display: flex;
              justify-content: flex-start;
            }

            .badge-new {
              position: absolute;
              right: 0;
              top: 0;
              color: #fff;
              background-color: #3fc4ff;
              font-size: 14px;
              height: 20px;
              width: 31px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
