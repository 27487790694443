@import 'src/assets/scss/color-palette';

.container {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  align-items: flex-end;
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 1;

  button {
    text-transform: none;
  }
}

.selected_items {
  display: flex;
}
