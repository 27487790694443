@import 'src/assets/scss/color-palette';

.empty_notes {
  color: $regent-gray;
  background: $snow-drift;
  margin: 20px;
  padding: 10px;
}

.list {
  padding: 20px 20px 0 20px;
  margin-bottom: 10px;
  max-height: calc(100vh - 300px);
  width: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: $light-gray;
  }

  &::-webkit-scrollbar-thumb {
    background: $regent-gray;
  }

  &__item {
    border: 1px solid $snow-drift;
    background: $snow-drift;
    padding: 15px;
    margin-bottom: 10px;

    &_important {
      background: $seashell-peach;
      border: 1px solid $yellow-orange;
    }

    &_header {
      display: flex;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 5px;

      &_link {
        margin-right: 5px;
      }

      &_desc {
        color: $regent-gray;
      }
    }

    &_content {
      color: $tuna;
      font-size: 16px;
      word-break: break-all;
    }
  }
}

