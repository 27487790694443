@import "src/assets/scss/color-palette";

.wrap {
  padding: 20px 20px 20px 40px;
  background: $snow-drift;
  display: flex;
  align-items: center;
  grid-gap: 15px;
}

.icon {
  color: $regent-gray;
  font-size: 20px;
  transform: rotateY(180deg);
}

.title {
  color: $bright-gray;
  text-transform: uppercase;
  font-weight: 700;
}

.description {
  color: $nevada;
   a, a:hover {
     color: $nevada;
   }
}