.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-top: 1px solid #ebeff2;

  :global {
    .data {
      display: flex;
      align-items: center;
      color: #84939a;

      .note-text {
        color: #2f333e;
        max-width: 500px;
      }

      .button-icon {
        .fa {
          font-size: 16px;
        }
      }
    }
  }
}
