@import 'src/assets/scss/color-palette';

.wrap {
  margin: 20px;
  border: 1px solid $tower-gray;

  .header {
    display: flex;
    background: $snow-drift;
    border: 1px solid $tower-gray;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 44px;

    .checkInfo {
      display: flex;
      align-items: center;
      grid-gap: 10px;
    }

    .badgeInfo {
      display: flex;
      align-items: center;
      grid-gap: 10px;
    }
  }

  .main {
    padding: 20px;
    border: 1px solid $tower-gray;
    border-top: none;

    .location {
      margin-top: 20px;
      display: flex;
      align-items: flex-end;
      grid-gap: 10px;
      width: 100%;
      > div:nth-child(1) {
        width: 40%;
      }
    }
  }

  .form {
    position: relative;
  }
}

