@import 'src/assets/scss/color-palette';

.wrap {
  &.disabled {
    cursor: not-allowed;

    [class$='-control'] {
      background-color: $light-gray;
    }

    [class$='-placeholder'] {
      background-color: $light-gray;
      color: $regent-gray;
      line-height: 3;
    }

    [class$='-indicatorContainer'] {
      color: $tower-gray;
    }

    [class$='-indicatorSeparator'] {
      background-color: $tower-gray;
    }

    [class$='-Input'] input {
      width: 0 !important;
    }
  }

  &.error {
    position: relative;

    [class$='-control'] {
      background-color: lighten($sunset-orange, 30%);
      border-color: $sunset-orange;
    }

    [class$='-Input'] input {
      width: 0 !important;
    }
  }

  [class$='-indicatorContainer'] {
    padding: 4px;
  }

  [class$='-control'] {
    background-color: $snow-drift;
    border-color: $tower-gray;
    min-height: 34px;
    min-width: 130px;
    line-height: 1;
  }

  [class$='-control--is-focused'],
  [class$='-control--menu-is-open'] {
    min-width: 130px;
    min-height: 34px;
  }

  [class$='-menu'] {
    z-index: 1000;
    font-size: 14px;

    [class*='option'],
    [class*='option--is-focused'],
    [class*='option--is-selected'] {
      max-width: 100%;
      height: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:not(:last-child) {
        border-bottom: 1px solid $light-gray;
      }
    }
  }
}
