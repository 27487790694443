@import 'assets/scss/color-palette';

.room {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.back {
  padding: 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $light-gray;
  cursor: pointer;
  color: $tuna;

  &:hover {
    background: $light-gray;
  }

  i {
    color: $regent-gray;
    margin-right: 10px;
    font-size: 20px;
  }
}

.driver {
  padding: 0 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $porcelain;
  position: relative;
  height: 130px;

  .left {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .badges {
    display: flex;
    position: absolute;
    top: -1px;
    right: 0;
    gap: 5px;

    .main {
      padding: 0 4px;
      border-radius: 3px;
      font-size: 14px;
      text-align: center;
      background-color: $loafer;
      border: 1px solid $loafer;
      color: $bright-gray;
    }
  }

  .title {
    max-width: 200px;
    color: $black-strong;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
  }

  .phone {
    margin-top: 5px;
    color: $bright-gray;
    line-height: 1;

    &.main {
      color: $cerulean;
    }
  }

  .truck {
    color: $regent-gray;

    a {
      color: $regent-gray;
      font-weight: 400;
      font-size: 14px;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
