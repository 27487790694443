.tabs-content {
  padding: 30px 74px;

  p {
    line-height: 24px;
  }

  h2 {
    font-size: 30px;
    font-weight: 300;
  }

  &__table {
    padding: 30px;
  }
}
