.message {
  padding: 15px 20px;

  :global {
    .fa {
      color: #ff5253;
      margin-right: 5px;
    }
  }
}
