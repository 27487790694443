.tooltip {
  cursor: default;
  position: relative;
  display: inline-block;

  .child {
    width: 100%;
  }

  .content {
    position: absolute;
    left: 0;
    top: 20px;
    background-color: #fff6ed;
    border: 1px solid rgba(#ffab40, 0.2);
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    color: #2f333e;
    font-size: 14px;
    line-height: 1.1;
    font-weight: 400;
    padding: 3px 5px;
    text-transform: none;
    transform: none;
    visibility: hidden;
    z-index: 10;
    transition: 0s visibility;
    text-align: left;

    &.button {
      left: 5px;
      top: 35px;
    }

    &.marker {
      left: -20px;
      top: -45px;
    }

    &.right {
      left: inherit;
      right: 0;
    }

    &.bottom {
      top: inherit;
      bottom: -25px;
    }

    @media only screen and (max-width: 1400px) {
      font-size: 13px;
    }

    @media only screen and (max-width: 1200px) {
      font-size: 12px;
    }

    div,
    span {
      white-space: nowrap;
    }
  }
}

.block {
  display: block;
}

.event {
  cursor: pointer;
}

.tooltip:hover .content {
  visibility: visible;
  transition-delay: 350ms;
  transform: scale(1, 1);
}

.disallow_select {
  user-select: none;
}
