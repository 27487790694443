@import 'src/assets/scss/color-palette';

.dropdown-multi-chbx-component {
  position: relative;

  .check-select {
    position: relative;

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #b0bfc6;
      background-color: #f7f9f8;
      min-height: 34px;
      width: 100%;
      height: 34px;
      min-width: 135px;
      padding: 6px 12px;
      border-radius: 4px;
      cursor: pointer;

      .fa {
        margin-left: 5px;
      }
    }

    &__list {
      display: none;
    }

    &.checked {
      background-color: #eceff1;

      .check-select {
        &__item {
          background-color: #eceff1;
        }
      }
    }

    &.open {
      .check-select {
        &__list {
          display: block;
          position: absolute;
          top: 40px;
          right: 0;
          box-shadow: 0 0 10px 3px rgba(38, 50, 56, 0.05);
          background: #fff;
          padding: 0 10px 10px;
          border-radius: 4px;
          z-index: 301;
          max-height: 230px;
          overflow-y: auto;
          min-width: 100%;
        }
      }
    }
  }

  .iw_cb_shell {
    display: inline-block;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    margin: 10px 7px 0;

    label {
      cursor: pointer;
      vertical-align: middle;
      font-size: 16px;
      margin: 0;
      padding: 0;
      text-transform: none;
      display: inline-block;
    }

    span.cb_square {
      vertical-align: middle;
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid #b0bfc6;
      background-color: #f7f9f8;
      text-align: center;
      margin: 0 3px;
      border-radius: 4px;

      &::before {
        content: "\f00c";
        font-family: 'FontAwesome', sans-serif;
        line-height: 18px;
        font-size: 14px;
        color: $tundora;
        display: none;
      }
    }

    &.f-right {
      float: right;
    }

    &.is_group_header {
      margin-left: -10px;

      label {
        font-weight: bold;
        color: $tundora;
      }
    }

    &.iw_cb_shell_checked span.cb_square {
      &::before {
        display: block;
      }
    }

    &:hover {
      span.cb_square {
        border-color: #363a45;
      }
    }

    &.disabled {
      pointer-events: none;
      cursor: not-allowed;

      span.cb_square {
        border: 1px solid #eceff1;
        background-color: #eceff1;
        cursor: not-allowed;
      }

      label {
        color: #b0bfc6;
        cursor: not-allowed;
      }
    }
  }

  .has-error {
    .iw_cb_shell {
      span.cb_square {
        border: 1px solid #ff5253;
        background-color: #ffeded;
      }
    }
  }

  .checkbox_single_component {
    position: relative;

    .overlay-trigger-wrap {
      display: inline-block;
    }
  }
}
