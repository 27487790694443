.list {
  border: 1px solid #ebeff2;
  border-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  min-height: 51px;

  :global {
    .item-title {
      color: #2f333e;
      font-weight: 300;
      display: flex;
      align-items: center;
      width: calc(100% - 200px);
      padding: 12px;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;

      .checkbox_single_component {
        .overlay-trigger-wrap {
          display: block;
        }
      }

      .iw_cb_shell {
        margin: 0;
        width: 100%;
        position: relative;

        label {
          font-weight: 300;
          font-size: 16px;
          text-transform: none;
          width: 100%;
          line-height: 20px;
          margin-left: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .file-badge {
        border: 1px solid #ebeff2;
        border-radius: 4px;
        background-color: #ebeff2;
        font-size: 14px;
        padding: 4px 7px;
        margin-left: 5px;
        white-space: nowrap;
        line-height: 1;
      }
    }

    .item-post-data {
      font-size: 14px;
      line-height: 16px;
      text-align: right;
      margin-right: 10px;

      .author {
        .second-link {
          word-break: normal;
        }
      }

      .post-date {
        color: #84939a;
      }
    }

    .item-buttons {
      padding: 5px;
      align-items: center;
      display: none;
    }
  }

  &:hover {
    background: #f6f9f8;

    :global {
      .item-buttons {
        display: flex;
      }

      .item-post-data {
        display: none;
      }
    }
  }
}
