@import 'src/assets/scss/color-palette';

@mixin scroll-disappear-breakpoint {
  @media screen and (min-height: 996px) { @content; }
}

$sidebar-width: 64px;
$sidebar-header-height: 60px;
$sidebar-support-item-height: 75px;
.navigation {
  z-index: 1995;
  position: sticky;
  left: 0;
  background-color: $bright-gray;

  :global {
    .side-bar {
      position: relative;
      left: 0;
      height: 100vh;
      font-size: 18px;
      background-color: $bright-gray;
      color: #b0bec5;
      z-index: 900;
      width: $sidebar-width;
    }

    .side-bar #side-bar-header-icon {
      display: block;
    }

    .side-bar #side-bar-header-label {
      opacity: 0;
    }

    .side-bar .side-bar-menu-label {
      opacity: 0;
      pointer-events: none;
      display: block;
    }

    .side-bar-open {
      width: 240px;

      .side-bar-menu {
        width: 240px;

        .sublist-item:hover {
          width: initial;
          max-width: 240px;
        }

        .sublist-item .side-bar-menu-item.sub-item::after {
          display: none;
        }
      }

      .side-bar-menu-item.active-page ::after {
        display: none;
      }

      .side-bar-header {
        width: 240px;
        //background for #celebrationNewYear theme;
        //background: url('../../../celebration/NewYear/imgs/leftNewYearMenuFull.svg') 0 0 no-repeat $cerulean;
        background-color: $cerulean;
        background-size: cover;
      }

      .support-item {
        padding: 20px;
        width: 240px;
        justify-content: space-between;

        &-entry {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .side-bar-open #side-bar-header-icon {
      display: none;
    }

    .side-bar-open #side-bar-header-label {
      -webkit-transition: opacity 0.35s ease-in-out;
      -moz-transition: opacity 0.35s ease-in-out;
      transition: opacity 0.35s ease-in-out;
      opacity: 1;
    }

    .side-bar-open .side-bar-menu-label {
      -webkit-transition: opacity 0.35s ease-in-out;
      -moz-transition: opacity 0.35s ease-in-out;
      transition: opacity 0.35s ease-in-out;
      opacity: 1;
    }

    .side-bar-header {
      height: $sidebar-header-height;
      width: $sidebar-width;
      //background for #celebrationNewYear theme;
      //background: url('../../../celebration/NewYear/imgs/leftNewYearMenuSlide.svg') 0 0 no-repeat $cerulean;
      background-color: $cerulean;
      background-size: cover;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;
    }

    .support-item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      height: $sidebar-support-item-height;
      width: $sidebar-width;
      background-color: $tuna;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 1000;

      i {
        color: #b0bfc6;
      }

      .commenting-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background-color: $bright-gray;
        border-radius: 100%;
        transition: 0.6s;
        cursor: pointer;

        &:hover {
          background-color: #039be6;
          color: $white;
        }
      }

      &-entry {
        display: none;

        &__title {
          color: $light-gray;
          line-height: 1;
          font-size: 16px;
        }

        &__phone {
          padding-top: 5px;
        }

        &__phone {
          color: $regent-gray;
          line-height: 1;
          font-size: 16px;
        }
      }
    }

    .side-bar h1 {
      font-family: 'Comfortaa', cursive;
      font-size: 22px;
      font-weight: 700;
      line-height: 60px;
      padding: 0 15px;
      margin: 0;
      color: $white;
      text-transform: lowercase;
    }

    .side-bar h2 {
      color: #5b7683;
      font-size: 16px;
      margin-top: 25px;
      margin-bottom: 10px;
      margin-left: 20px;
    }

    .side-bar-menu {
      text-transform: uppercase;
      height: calc(100% - #{$sidebar-header-height} - #{$sidebar-support-item-height});
      position: fixed;
      width: $sidebar-width;
      top: $sidebar-header-height;
      background-color: $bright-gray;
      overflow-y: auto;
    }

    .scroll1:-moz-read-only {
      scrollbar-width: thin;
    }
    .scroll1::-webkit-scrollbar {
      width: 5px;
    }

    .scroll1::-webkit-scrollbar-track {
      background: $tuna;
    }

    .scroll1::-webkit-scrollbar-thumb {
      background: $regent-gray;
    }

    .side-bar-menu-item {
      display: block;
      padding: 20px;
      position: relative;
      background-color: $bright-gray;

      &.active-page {
        color: $white;

        & ::after {
          content: "\f0d9";
          position: absolute;
          top: 50%;
          right: 0;
          font-family: FontAwesome;
          line-height: 1;
          color: $light-porcelain;
          font-size: 18px;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
    }

    // hides triangle(active page) when hover on menu item (when leftbar is collapsed)
    .sublist-item:hover {
      .side-bar-menu-item {
        &.active-page {
          & ::after {
            display: none;
          }
        }
      }
    }

    .side-bar-menu-item::after {
      content: "\f105";
      position: absolute;
      top: 50%;
      right: -30px;
      font-family: FontAwesome;
      line-height: 1;
      color: $white;
      font-size: 18px;
      transform: translateY(-50%);
      display: none;
    }

    .side-bar-open .side-bar-menu-item::after,
    .sublist-item:hover .side-bar-menu-item::after {
      right: 20px;
    }

    .side-bar-menu li .active.side-bar-menu-item::after,
    .side-bar-menu .active .side-bar-menu-item::after {
      content: "\f0d9";
      position: absolute;
      top: 50%;
      right: 0;
      font-family: FontAwesome;
      line-height: 1;
      color: $light-porcelain;
      font-size: 18px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    .side-bar li.active a {
      color: $white;
    }

    .side-bar li.active > .side-bar-menu-item::after {
      display: inline;
    }

    .side-bar ul li {
      border-bottom: 1px solid $tuna;
    }

    .side-bar ul li a {
      font-size: 18px;
      color: #b0bec5;
      text-decoration: none;
      display: flex;
      white-space: nowrap;
    }

    .side-bar ul li .fa {
      margin-right: 15px;
      margin-top: 4px;
    }

    .side-bar-menu-item:hover {
      background-color: $tuna;
      color: $white;
    }

    .side-bar-menu-item-choosen {
      background-color: #324148;
      color: $white;
    }

    .side-bar-menu-item-choosen::after {
      display: inline;
    }

    // SIDEBAR SUBLIST

    .side-bar:not(.side-bar-open) {
      .side-bar-menu {
        direction: rtl;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        overflow-x: hidden;
        width: initial;
        min-width: $sidebar-width;
        background-color: unset;
      }

      .sublist-item {
        width: $sidebar-width;
        flex-shrink: 0;
        margin-left: -5px;
        direction: ltr;

        @include scroll-disappear-breakpoint {
          margin-left: 0;
        }

        i {
          margin-left: 5px;

          @include scroll-disappear-breakpoint {
            margin-left: 0;
          }
        }

        &:-moz-read-only {
          margin-left: -8px;

          @include scroll-disappear-breakpoint {
            margin-left: 0;
          }

          i {
            margin-left: 8px;

            @include scroll-disappear-breakpoint {
              margin-left: 0;
            }
          }
        }

        .sublist {
          position: absolute;
          right: 0;
          width: calc(100% - #{$sidebar-width} + 8px);
          background-color: $bright-gray;
          display: none;

          // &:-moz-read-only {
          //   left: 56px;
          // }
        }

        &-active:hover {
          border-color: $bright-gray;

          .sublist {
            display: block;

            .side-bar-menu-item.sub-item::before {
              display: none;
            }
          }
        }
      }
    }

    .side-bar-menu {
      .sublist-item {
        overflow: hidden;
        height: 65.71px;
        display: block;

        &:hover {
          width: 240px;

          .side-bar-menu-label {
            opacity: 1;
            pointer-events: none;
          }
        }

        li {
          border: none;
        }

        .sublist-header {
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none;

          .side-bar-menu-item {
            &::after {
              font-family: FontAwesome;
              content: '\f107';
              display: inline;
            }
          }
        }

        .side-bar-menu-item.sub-item {
          text-transform: capitalize;
          font-size: 16px;
          padding: 10px 20px;

          &::before {
            font-family: FontAwesome;
            content: '\f111';
            font-size: 8px;
            margin-left: 7px;
            margin-right: 20px;
            margin-top: 6px;
          }
        }
      }

      .sublist-item-active {
        height: auto;
        background-color: $tuna;

        .sublist-header {
          .side-bar-menu-item::after {
            font-family: FontAwesome;
            content: '\f106';
            display: inline;
            color: inherit;
          }
        }

        .side-bar-menu-item {
          background-color: $tuna;
        }
      }
    }
  }
}
