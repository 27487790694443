.right-buttons-block {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.border-payment {
    border-bottom: 2px solid #ebeff2;
    padding-top: 5px;

    .button {
      min-width: 165px;
      color: #fff;

      .fa {
        color: #fff;
      }
    }
  }
}
