.wrap {
  display: flex;

  :global {
    .form-group {
      width: 270px;
    }
  }

  p {
    max-width: 325px;
    width: 100%;
  }
}
