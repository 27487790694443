@import 'assets/scss/color-palette';

.upload {
  margin-bottom: 15px;

  :global {
    .button-attach {
      min-width: 20px;
      position: absolute;
      bottom: 17px;
      left: 0;
      overflow: hidden;
      z-index: 100;

      .fa {
        margin: 0;
        font-size: 20px;
      }

      &:hover {
        background: $white;
      }

      input[type='file'] {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }

    .attachments-list {
      margin-top: 10px;
      margin-left: 40px;
      display: flex;
      flex-wrap: wrap;
    }

    .count-preview {
      margin-left: 40px;
      color: $cerulean;
      font-size: 14px;
    }
  }
}
