@import "src/assets/scss/color-palette";

.dialog {
  width: 1000px;
}

.header {
  width: 100%;
}

.body {
  padding: 0;
}

.footer {
  height: 72px;
  display: flex;
  justify-content: flex-end;
}

.wrapper {
  padding: 0 1rem;
}

.borderTop {
  border-top: 1px solid $porcelain;
}
