@import 'assets/scss/color-palette';

.warning {
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid $sunset-orange;

  :global {
    .form-group {
      margin: 0;
    }

    .iw_rb_shell.iw_rb_horisontal > div:nth-child(2) {
      margin-left: 40px;
    }
  }

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 18px;
    color: $sunset-orange;
  }
}

.warning__info {
  padding: 15px;
  display: flex;
  background-color: $fair-pink;
  border-radius: 5px 5px 0 0;
}
