.flex-block {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .form-group {
    width: 100%;
  }
}

.text-right {
  &.flex-block {
    justify-content: flex-end;
    align-items: center;
  }
}
