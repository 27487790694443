.stop {
  :global {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f7f9f8;
      padding: 10px 12px;
      border-bottom: 1px solid #ebeff2;

      &_right {
        display: flex;
        align-items: center;
      }
    }

    .files {
      display: flex;
      flex-wrap: wrap;
      padding: 6px;

      &.list {
        display: block;
        padding: 0;
      }
    }

    .checkbox-custom__block {
      margin-left: 10px;
    }

    .checkbox-custom__block .checkbox {
      display: none;
    }

    .checkbox-custom__item {
      position: relative;
      width: 20px;
      height: 20px;
      border: 1px solid #b0bfc6;
      background-color: #f7f9f8;
      border-radius: 2px;
      cursor: pointer;
    }

    .checkbox-custom__item,
    label {
      display: inline-block;
      vertical-align: middle;
    }

    .checkbox:checked + .checkbox-custom__item::before {
      content: "\f00c";
      font-family: FontAwesome, sans-serif;
      display: block;
      position: absolute;
      font-size: 14px;
      top: 0;
      left: 2px;
    }
  }
}
