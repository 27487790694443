@import 'src/assets/scss/color-palette';

.modal {
  width: 698px;
  margin: 30px auto;

  :global {
    .modal-header {
      background: $snow-drift;
      align-items: center;
      padding: 20px;

      button {
        font-size: 20px;
      }
    }

    .modal-title {
      font-weight: 400;
      font-size: 20px;
      color: $tuna;
    }

    .modal-body {
      padding: 0;
    }
  }
}