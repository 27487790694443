@import 'assets/scss/color-palette';

.wrapper {
  padding: 1rem;
  display: flex;
}

.counter {
  margin-right: 20px;
}

.error {
  border: 1px solid $sunset-orange;
  text-decoration: none;
  border-radius: 3px;
  padding: 5px 5px;
  color: $white;
  background-color: $sunset-orange;
  line-height: 1.3;
  font-weight: 400;
  font-size: 14px;
}
