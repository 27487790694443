.item {
  font-size: 14px;

  :global {
    .fa {
      font-size: 14px;
      color: #84939a;
      margin-right: 4px;
    }
  }
}

.phone {
  display: inline-flex;

  :global {
    .fa {
      font-size: 16px;
      color: #84939a;
      margin-right: 8px;
    }
  }
}
