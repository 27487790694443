.item {
  border: 1px solid #ebeff2;
  border-radius: 5px;
  margin-top: 20px;

  :global {
    .header {
      background: #ebeff2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 0 5px 10px;
      height: 45px;
    }

    .main {
      display: none;

      &.open {
        display: block;
      }
    }

    .footer {
      background-color: #f7f9f8;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .total {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 700;

        .data {
          margin-left: 20px;
          width: 110px;
          padding-right: 20px;
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .settlements-group-table {
      thead {
        tr {
          th {
            background: #f7f9f8;
            padding: 10px;
            color: #84939a;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      tbody {
        tr {
          td {
            vertical-align: top;
            border: 1px solid #ebeff2;
            padding: 10px;

            &:nth-child(1) {
              width: 130px;
              border-left: none;
            }

            &:nth-child(2) {
              width: 200px;
            }

            &:nth-child(3) {
              width: 250px;

              > div {
                width: 220px;
              }
            }

            &:nth-child(4) {
              width: 80px;
            }

            &:nth-child(5) {
              width: 170px;
            }

            &:nth-child(6) {
              width: 130px;
              padding-right: 30px;
            }

            .payment {
              display: flex;
              justify-content: flex-start;
            }
          }
        }
      }

      &__total {
        background: #f7f9f8;
        display: flex;
        justify-content: flex-end;
        padding: 10px;
        border-bottom: 1px solid #ebeff2;

        .total-miles {
          width: 340px;
          display: flex;
          justify-content: flex-end;

          .data {
            margin-left: 20px;
            padding-left: 10px;
            width: 80px;
          }
        }

        .total-payment {
          width: 285px;
          display: flex;
          justify-content: flex-end;

          .data {
            margin-left: 20px;
            width: 110px;
            padding-right: 20px;

            div {
              display: flex;
              justify-content: space-between;

              .reim {
                position: relative;

                &::before {
                  position: absolute;
                  left: -10px;
                  content: '+';
                }
              }

              .ded {
                position: relative;

                &::before {
                  position: absolute;
                  left: -10px;
                  content: '-';
                }
              }
            }
          }
        }
      }
    }
  }
}

.send {
  &_row {
    display: flex;
    align-items: center;
  }

  :global {
    .checkbox_single_component {
      width: 450px;

      label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 420px;
      }
    }
  }
}
