@import "assets/scss/color-palette";

.modal:global(.modal-content) {
  width: 600px;
  margin: 30px auto;

  :global {
    .modal-header {
      padding: 20px;

      .modal-title.h4 {
        font-size: 20px;

        i {
          margin-left: 5px;
          color: $cerulean;
        }
      }

      .close {
        font-size: 20px;
      }
    }
  }
}

.description {
  padding: 20px;
  border-bottom: 1px solid $porcelain;
}

.settings {
  padding: 20px 20px 0 20px;

  &__row {
    border-bottom: 1px solid $porcelain;
    margin-bottom: 20px;
  }

  &__title {
    font-weight: 700;
    padding-bottom: 15px;
  }

  .colors_group {
    &__title {
      padding-bottom: 10px;
    }

    &__horizontal {
      display: flex;
      grid-gap: 50px;
    }

    &__vertical {
      display: block;
    }
  }
}

.footer {
  display: flex;
  border-top: 1px solid $porcelain;
  justify-content: space-between;
  padding: 20px;

  :global {
    .button {
      text-transform: none;
    }
  }
}
