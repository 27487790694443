.body {
  display: flex;
}

.wrapper {
  padding: 0 1rem;
}

.footer {
  display: flex;
  justify-content: flex-end;
}
